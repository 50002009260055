import React, { useEffect, useMemo } from 'react';
import { Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { QueryType } from 'components/commons/IssueCrashFilter/IssueCrashFilterExUtil';

const VALUE_ALL = 'ALL';
const VALUE_YES = 'YES';
const VALUE_NO = 'NO';

const AppInBackSelect = ({
  isReadOnly,
  fieldValue,
  onChangeFieldValue,
}) => {
  const { t } = useTranslation();

  const options = useMemo(() => ([
    { label: t('issueCrashFilter.anyPlaceholder'), value: VALUE_ALL },
    { label: t('REPORTDETAIL.foreground'), value: VALUE_NO },
    { label: t('REPORTDETAIL.background'), value: VALUE_YES },
  ]), []);

  const {
    term,
  } = fieldValue || {};

  let selectValue;
  if (term === true) {
    selectValue = VALUE_YES;
  } else if (term === false) {
    selectValue = VALUE_NO;
  } else {
    selectValue = VALUE_ALL;
  }

  return <Select
    style={{ width: '100%', fontSize: '14px' }}
    options={options}
    value={selectValue}
    onChange={(v) => {
      if (isReadOnly) {
        return;
      }
      if (v === VALUE_ALL || !v) {
        onChangeFieldValue({});
      } else if (v === VALUE_YES) {
        onChangeFieldValue({ queryType: QueryType.TERM, term: true });
      } else if (v === VALUE_NO) {
        onChangeFieldValue({ queryType: QueryType.TERM, term: false });
      }
    }}
  />
}

export default AppInBackSelect;
