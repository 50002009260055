import React, {Suspense} from 'react';
import { Route, Switch } from 'react-router-dom';

// import {
//   ConfigPage,
//   TagMgrPage,
//   SymbolTableConfigPage,
//   TapdConfigPage,
//   NintendoSwitchConfigPage,
//   SdkStrategyPage,
//   SdkStrategyConfig,
//   ObfuscationFileSettingPage,
//   CountryGroupConfigPage,
// } from 'containers';
// import UserIdDescriptionConfigPage from 'containers/UserIdDescriptionConfigPage';
// import IssueClassificationSettingPage from 'containers/IssueClassificationSettingPage';
// import { ProductBase } from 'components/product';

import { LoadingPage } from 'route/index'

const ConfigPage = React.lazy(() => import( /* webpackChunkName: "report-config-page" */ 'containers/ReportConfigPage'));
const TagMgrPage = React.lazy(() => import( /* webpackChunkName: "tag-mgr-page" */ 'containers/TagMgrPage'));
const SymbolTableConfigPage = React.lazy(() => import( /* webpackChunkName: "symbol-table-config-page" */ 'containers/SymbolTableConfigPage'));
const TapdConfigPage = React.lazy(() => import( /* webpackChunkName: "tapd-config-page" */ 'containers/TapdConfigPage/TapdConfigPage'));
const NintendoSwitchConfigPage = React.lazy(() => import( /* webpackChunkName: "nintendo-switch-config-page" */ 'containers/NintendoSwitchConfigPage'));
const SdkStrategyPage = React.lazy(() => import( /* webpackChunkName: "sdk-strategy-page" */ 'containers/SdkStrategyPage'));
const SdkStrategyConfig = React.lazy(() => import( /* webpackChunkName: "sdk-strategy-config" */ 'containers/SdkStrategyConfig'));
const ObfuscationFileSettingPage = React.lazy(() => import( /* webpackChunkName: "obfuscation-file-setting-page" */ 'containers/ObfuscationFileSettingPage'));
const CountryGroupConfigPage = React.lazy(() => import( /* webpackChunkName: "country-group-config-page" */ 'containers/CountryGroupConfigPage'));
const Efficiency = React.lazy(() => import( /* webpackChunkName: "sdk-strategy-page" */ 'containers/Efficiency'));

const UserIdDescriptionConfigPage = React.lazy(() => import( /* webpackChunkName: "user-id-description-config-page" */ 'containers/UserIdDescriptionConfigPage'));
const IssueClassificationSettingPage = React.lazy(() => import( /* webpackChunkName: "issue-classification-setting-page" */ 'containers/IssueClassificationSettingPage'));
const ProductBase = React.lazy(() => import( /* webpackChunkName: "product-base" */ 'components/product/ProductBase/index'));
const DashboardFilters = React.lazy(() => import( /* webpackChunkName: "dashboard-filters" */ 'components/product/DashboardFilters/DashboardFilters'));

/* eslint-disable react/prop-types */
export default ({ match }) => (
  <Suspense fallback={<LoadingPage/>}>
    <Switch>
      <Route path={`${match.url}/notification/:appId`} component={ConfigPage} />
      <Route path={`${match.url}/tags/:appId`} component={TagMgrPage} />
      <Route path={`${match.url}/dsyms/:appId`} component={SymbolTableConfigPage} />
      <Route path={`${match.url}/tapd/:appId`} component={TapdConfigPage} />
      <Route path={`${match.url}/country-group-config/:appId`} component={CountryGroupConfigPage} />
      <Route path={`${match.url}/dashboard-filters/:appId`} component={DashboardFilters} />
      <Route path={`${match.url}/nintendo-switch/:appId`} component={NintendoSwitchConfigPage} />
      <Route path={`${match.url}/obfuscation-file-setting/:appId`} component={ObfuscationFileSettingPage} />
      <Route path={`${match.url}/issue-classification-setting/:appId`} component={IssueClassificationSettingPage} />
      <Route path={`${match.url}/user-id-description-config/:appId`} component={UserIdDescriptionConfigPage} />
      <Route path={`${match.url}/sdk-strategy-config/:appId`} component={SdkStrategyConfig} />
      <Route path={`${match.url}/sdk-strategy/:appId`} component={SdkStrategyPage} />
      <Route path={`${match.url}/efficiency/:appId`} component={Efficiency} />
      <Route path={`${match.url}/member-management/:appId`} render={(props) => <ProductBase currentTab='PRODUCTBASE.members' {...props}/>}/>
      <Route path={`${match.url}/audit-log/:appId`} render={(props) => <ProductBase currentTab='PRODUCTBASE.operationLogs' {...props}/>}/>
      <Route path={`${match.url}/product-info/:appId`} render={(props) => <ProductBase currentTab='PRODUCTBASE.information' {...props}/>}/>
      <Route path={`${match.url}/version-management/:appId`} render={(props) => <ProductBase currentTab='PRODUCTBASE.versionManage' {...props}/>}/>
      <Route path={`${match.url}/delete-project/:appId`} render={(props) => <ProductBase currentTab='PRODUCTBASE.deleteProduct' {...props}/>}/>
      <Route path={`${match.url}/web-console-config/:appId`} render={(props) => <ProductBase currentTab='PRODUCTBASE.webConsoleConfiguration' {...props}/>} />
      <Route path={`${match.url}/super-admin-server-settings/:appId`} render={(props) => <ProductBase currentTab='PRODUCTBASE.functionConfiguration' {...props}/>}/>
      <Route path={`${match.url}/super-admin-notice-config/:appId`} render={(props) => <ProductBase currentTab='PRODUCTBASE.notification' {...props}/>}/>
    </Switch>
  </Suspense>
);
