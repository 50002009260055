import React, { useEffect } from 'react';
import {
  selectIssueCrashFilterOsVersionOptionsInfo
} from 'utils/selectors/selectors';
import { actionFetchModelOptions, actionFetchOsVersionOptions } from 'reducers/seniorSearch';
import { useDispatch, useSelector } from 'react-redux';
import VersatileKeywordSearch from 'components/commons/IssueCrashFilter/VersatileKeywordSearch';
import { QueryType } from 'components/commons/IssueCrashFilter/IssueCrashFilterExUtil';

const OsVersionSearch = ({
  appId,
  isReadOnly,
  value,
  onChange,
}) => {
  const { hasFetched, options } = useSelector(selectIssueCrashFilterOsVersionOptionsInfo);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!appId) {
      return;
    }
    if (!hasFetched) {
      dispatch(actionFetchOsVersionOptions());
    }
  }, [appId]);

  return <VersatileKeywordSearch
    defaultQueryType={QueryType.TERMS}
    hasIsEmptyQueryType={false}
    value={value}
    onChange={onChange}
    isReadOnly={isReadOnly}
    queryTypeTermsOptions={options}
  />;
};

export default OsVersionSearch;
