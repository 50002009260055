import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { IssueListTrendTypeEnum } from 'utils/constants';
import style from './style.scss';
import i18n from 'i18n.js';
import { Translation, useTranslation } from 'react-i18next';
import { Button, Divider, Tag } from 'antd';
import { connect, useDispatch, useSelector } from 'react-redux';
import { TapdModal } from 'components/exception/issue';
import IssueTapdBugStatusEx from 'components/exception/BaseProblemPage/IssueItem/IssueTapdBugStatusEx';
import { ze } from 'utils/zhEn';
import { DemoUtil } from 'utils/demo-util';
import IssueTrendBarChart from 'components/exception/BaseProblemPage/IssueItem/IssueTrendBarChart';
import { formatEnNumber } from 'utils/format/format-number';
import { isNotNullish, isNullish } from 'utils/nullish';
import { TagList } from 'components/commons';
import { bindActionCreators } from 'redux';
import * as issueActions from 'reducers/issue/issueActions';
import { CS_STYLES } from 'utils/constants/style-constants';
import TagIcon from 'svg/v2/newcs_dashboard_crashanalysis_issuedetail_tag_icon.svg';
import StatusItem from 'components/commons/StatusItem/StatusItem';
import { MERGE_TYPE_IS_BE_MERGED_ISSUE, MERGE_TYPE_IS_MERGE_ISSUE } from 'utils/constants/merge-issue-type';
import { ErrorBoundary } from 'react-error-boundary';
import { selectHasSpecifiedPermissionInCurrentApp } from 'utils/selectors/selectors';
import { RolePermission } from 'utils/constants/role-permission';

/*
issue detail head
 */

const IssueHead = ({
  path,
  issue,
  changeState,
  members,
  demoApp,
  tapdBugDetail,
  isSubheaderFixed,
  onRefreshIssueInfo,
  reduxState,
  actions,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const allowViewDashboard = useSelector(state => selectHasSpecifiedPermissionInCurrentApp(state, RolePermission.VIEW_DASHBOARD));

  function handleChangeIssueStatus(assigneeList, status, membersList) {
    const { issueId } = issue && issue.toJS && issue.toJS() || {};
    !DemoUtil.checkAndWarnIfDemoApp(demoApp) && changeState && changeState({
      issueId,
      assigneeList,
      members: membersList,
      status,
      page: 'issueDetail',
    });
  }
  const currentApp = reduxState.app.get('current').toJS() || {};
  const { appId, platformId: pid } = currentApp;

  const {
    addTag, delTag
  } = actions;

  const {
    imeiCount, issueId, count, exceptionName, exceptionMessage, keyStack, status, bugs,
    assigneeList, esMap,
  } = issue.toJS();

  const bugTrackingSimplifiedInfo = useMemo(() => reduxState.app.get('appIdToBugTrackingSimplifiedInfo')?.[appId],
    [reduxState.app, appId]);
  const { tapdWorkspaceId, jiraProjectId } = bugTrackingSimplifiedInfo || {};
  const showBugStatusField = !!tapdWorkspaceId || !!jiraProjectId;

  const hasAssignee = assigneeList && assigneeList.length > 0;

  const issueIdToDailyTrendList = reduxState.issue.get('issueIdToDailyTrendList') || {};
  const issueIdToHourlyTrendList = reduxState.issue.get('issueIdToHourlyTrendList') || {};

  let type = status;
  let arr = [];
  if (members) {
    arr = (members.get && members.get('options')) || members.options;
  }

  const memberList = arr.map((member) => ({
    label: (member.get && member.get('label')) || member.label,
    value: (member.get && member.get('value')) || member.value,
  }));// 这里传进去成员列表

  const [tapdModalVisible, setTapdModalVisible] = useState(false);

  const modalProps = {
    visible: tapdModalVisible,
    onCancel: () => setTapdModalVisible(false),
    onOk: () => {
      setTapdModalVisible(false);
      onRefreshIssueInfo();
    },
  };

  const tapdBugInfo = bugs && bugs[0];
  const { status: tapdBugStatus } = tapdBugDetail || {};

  const regularStylePresentation =  <div className={style.issue_head}>
    <div className={style.error_title}>
      <div style={{ display: 'flex', gap: '4px' }}>
        <div style={{ color: '#AAA' }}>{ t('issueCrashFilterKey.errorType') }:</div>
        <div style={{ color: CS_STYLES.LINK_COLOR }}>{ exceptionName || '-' }</div>
      </div>
      <div style={{ display: 'flex', gap: '4px' }}>
        <div style={{ color: '#AAA' }}>{ ze('首次上报异常消息', 'First Report Exception Message') }:</div>
        <div style={{ color: CS_STYLES.LINK_COLOR }}>{ exceptionMessage || '-' }</div>
      </div>
    </div>
    <div className={style.statsArea}>
      <div style={{ flex: '0 0 150px' }}>
        <div className={style.issueHeadItem}>
          <div className={style.statsTitle}>{ t("CRASHDETAIL.totalReports") }</div>
          <div className={style.statsValue} style={{ fontSize: '24px', fontWeight: 700 }}>{ formatEnNumber(count) }</div>
        </div>
      </div>
      <div style={{ flex: '0 0 150px' }}>
        <div className={style.issueHeadItem} style={{ position: 'relative' }}>
          <div className={style.statsTitle}>{ t("CRASHDETAIL.totalDevices") }</div>
          <div className={style.statsValue} style={{ fontSize: '24px', fontWeight: 700 }}>{formatEnNumber(imeiCount)}</div>
          {(esMap?.mergeIssueType === MERGE_TYPE_IS_MERGE_ISSUE) && <p style={{ position: 'absolute', top: 15, fontSize: '12px', width: 'max-content', color: CS_STYLES.SUB_TEXT_COLOR }}>{ze('统计90天内数据', 'Data analysis within 90 days')}</p>}
        </div>
      </div>
      { allowViewDashboard && <Divider type='vertical' style={{ height: 'auto', margin: 0 }}/> }
      { allowViewDashboard && <div style={{ flex: '0 0 300px' }}>
        <div className={style.statsTitle}>{ t('WORKBENCH.最近24小时') }</div>
        <div
          className={style.statsValue}
        >
          <IssueTrendBarChart
            style={{ width: '100%', height: '100%' }}
            trendType={IssueListTrendTypeEnum.HOURLY}
            dailyTrendList={issueIdToDailyTrendList[issueId]}
            hourlyTrendList={issueIdToHourlyTrendList[issueId]}
          />
        </div>
      </div> }
      { allowViewDashboard && <div style={{ flex: '0 0 180px' }}>
        <div className={style.statsTitle}>{ t('WORKBENCH.最近14天') }</div>
        <div
          className={style.statsValue}
        >
          <IssueTrendBarChart
            style={{ width: '100%', height: '100%' }}
            trendType={IssueListTrendTypeEnum.DAILY}
            dailyTrendList={issueIdToDailyTrendList[issueId]}
            hourlyTrendList={issueIdToHourlyTrendList[issueId]}
          />
        </div>
      </div> }
      { !demoApp && <Divider type='vertical' style={{ height: 'auto', margin: 0 }}/> }
      { !demoApp && <div style={{ flex: '0 0 150px' }}>
        <div className={style.issueHeadItem}>
          <div className={style.statsTitle}>{ t("TOPISSUELIST.status") }</div>
          <div className={style.statsValue}>
            { !hasAssignee && !demoApp && <Button onClick={() => handleChangeIssueStatus(assigneeList, type, memberList)}>{ t("CRASHDETAIL.stateChange") }</Button> }
            { hasAssignee && <StatusItem
              assigneeList={assigneeList}
              statusNo={type}
              handleChangeStatus={() => handleChangeIssueStatus(assigneeList, type, memberList)}
            />}
          </div>
        </div>
      </div> }
      { !demoApp && showBugStatusField && <div style={{ flex: '0 0 120px' }}>
        <div className={style.issueHeadItem}>
          { !!tapdWorkspaceId && <div className={style.statsTitle}>{ ze('TAPD状态', 'Tapd Status') }</div> }
          { !!jiraProjectId && <div className={style.statsTitle}>{ ze('Jira缺陷单状态', 'Jira Issue Status') }</div> }
          <div className={style.statsValue}>
            { isNullish(tapdBugInfo) && <Button onClick={() => setTapdModalVisible(true)}>{ t('issueCrashFilterKey.createBugForm') }</Button> }
            { isNotNullish(tapdBugInfo) && <IssueTapdBugStatusEx
              currentApp={currentApp}
              issueId={issueId}
              tapdBugInfo={tapdBugInfo}
              tapdBugStatus={tapdBugStatus}
              onBugUnbind={(issueId) => onRefreshIssueInfo()}
            /> }
          </div>
        </div>
      </div> }
    </div>
    { !demoApp && <Divider style={{ margin: '24px 0 10px' }}/> }
    { !demoApp && <div className={style.tagArea}>
      <TagIcon style={{ marginTop: '4px', minWidth: '14px' }}/>
      <div style={{ marginTop: '4px', marginLeft: '4px', minWidth: '28px' }}>{ t("CRASHDETAIL.tag") }</div>
      <div style={{ marginLeft: '16px' }}>
        <TagList
          tags={issue.get('tagInfoList')}
          {...{
            dispatch, addTag, delTag, issueId, path, demoApp, appId, pid,
          }} />
      </div>
    </div> }
    <TapdModal
      issueInfoList={[issue.toJS()]}
      modalProps={modalProps}
    />
  </div>;

  const scrolledStyleRendering = <ErrorBoundary fallback={<div style={{ color: 'red' }}>渲染故障</div>}><div style={{ padding: '12px 0 14px 24px'}}>
    <div style={{ display:'flex',gap:'23px', alignItems:'center' }}>
      <div style={{ display:'flex',alignItems:'center' }}>
        <div style={{ color: CS_STYLES.SUB_TEXT_COLOR, fontSize:'12px', marginRight:'9px' }}>{ t('issueCrashFilterKey.errorType') }:</div>
        <div style={{ color: CS_STYLES.LINK_COLOR, fontSize:'14px',maxWidth:'200px', overflow:'hidden', whiteSpace:'nowrap',textOverflow:'ellipsis' }}>{ exceptionName || '-' }</div>
      </div>
      <div style={{ display:'flex',alignItems:'center' }}>
        <div style={{ color: CS_STYLES.SUB_TEXT_COLOR, fontSize:'12px', marginRight:'9px' }}>{ ze('首次上报异常消息', 'First Report Exception Message') }:</div>
        <div style={{ color: CS_STYLES.LINK_COLOR, fontSize:'14px',maxWidth:'200px', overflow:'hidden', whiteSpace:'nowrap',textOverflow:'ellipsis' }}>{ exceptionMessage || '-' }</div>
      </div>
      <div style={{ display:'flex',alignItems:'center' }}>
        <div style={{ color: CS_STYLES.SUB_TEXT_COLOR, fontSize:'12px', marginRight:'9px' }}>{ t("CRASHDETAIL.totalReports") }</div>
        <div className={style.statsValue} style={{ fontSize: '14px', fontWeight: 700 }}>{ formatEnNumber(count) }</div>
      </div>
      <div style={{ display:'flex', alignItems:'center'}}>
        <div style={{ marginRight:'9px' }}>
          <div style={{ color: CS_STYLES.SUB_TEXT_COLOR, fontSize:'12px' }}>{ t("CRASHDETAIL.totalDevices") }</div>
          {(esMap?.mergeIssueType === MERGE_TYPE_IS_MERGE_ISSUE) && <div style={{ fontSize: '12px', color: CS_STYLES.SUB_TEXT_COLOR }}>{ze('统计90天内数据', 'Data analysis within 90 days')}</div>}
        </div>
        <div className={style.statsValue} style={{ fontSize: '14px', fontWeight: 700 }}>{formatEnNumber(imeiCount)}</div>
      </div>
      <div style={{ display: 'flex', gap: '4px', alignItems:'center' }}>
        <div style={{ color: CS_STYLES.SUB_TEXT_COLOR, fontSize:'12px', marginRight:'9px' }}>{ t("TOPISSUELIST.status") }</div>
        <div className={style.statsValue}>
          { !hasAssignee && !demoApp && <Button size="small" onClick={() => handleChangeIssueStatus(assigneeList, type, memberList)}>{ t("CRASHDETAIL.stateChange") }</Button> }
          { hasAssignee && <StatusItem
            assigneeList={assigneeList}
            statusNo={type}
            handleChangeStatus={() => handleChangeIssueStatus(assigneeList, type, memberList)}
          />}
        </div>
      </div>
      <div style={{ display: 'flex', gap: '9px', alignItems:'center' }}>
        { !!tapdWorkspaceId && <div style={{ color: CS_STYLES.SUB_TEXT_COLOR, fontSize:'12px' }}>{ ze('TAPD状态', 'Tapd Status') }</div> }
        { !!jiraProjectId && <div style={{ color: CS_STYLES.SUB_TEXT_COLOR, fontSize:'12px' }}>{ ze('Jira缺陷单状态', 'Jira Issue Status') }</div> }
        <div className={style.statsValue}>
          { isNullish(tapdBugInfo) && <Button size="small" onClick={() => setTapdModalVisible(true)}>{ t('issueCrashFilterKey.createBugForm') }</Button> }
          { isNotNullish(tapdBugInfo) && <IssueTapdBugStatusEx
            currentApp={currentApp}
            issueId={issueId}
            tapdBugInfo={tapdBugInfo}
            tapdBugStatus={tapdBugStatus}
            onBugUnbind={(issueId) => onRefreshIssueInfo()}
          /> }
        </div>
      </div>
    </div>
    { !demoApp && <div style={{ display: 'flex', gap: '4px', alignItems:'center' }}>
      <TagIcon style={{ marginTop: '4px', minWidth: '14px' }}/>
      <div style={{ marginTop: '4px', marginLeft: '4px', minWidth: '28px' }}>{ t("CRASHDETAIL.tag") }</div>
      <div style={{ marginLeft: '16px' }}>
        <TagList
          tags={issue.get('tagInfoList')}
          {...{
            dispatch, addTag, delTag, issueId, path, demoApp, appId, pid,
          }} />
      </div>
    </div> }
    <TapdModal
      issueInfoList={[issue.toJS()]}
      modalProps={modalProps}
    />
  </div></ErrorBoundary>

  return <div>
    {isSubheaderFixed ? scrolledStyleRendering : regularStylePresentation}
  </div>
};

IssueHead.propTypes = {
  issue: PropTypes.object.isRequired,
  changeState: PropTypes.func,
  members: PropTypes.object,
  demoApp: PropTypes.bool,
  tapdBugDetail: PropTypes.object,
  onRefreshIssueInfo: PropTypes.func,
};

const mapStateToProps = state => ({
  reduxState: state,
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({
    ...issueActions,
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(IssueHead);
