// global actions
export const LOCATION_CHANGE = '@@router/LOCATION_CHANGE';
export const FETCH_FAILURE = 'FETCH_FAILURE';
export const SHOW_LOADING = 'SHOW_LOADING';
export const HIDE_LOADING = 'HIDE_LOADING';
export const SHOW_BULLETIN = 'SHOW_BULLETIN';
export const HIDE_BULLETIN = 'HIDE_BULLETIN';
export const UNACTIVE_POP_ALERT = 'UNACTIVE_POP_ALERT';
export const ACTIVE_POP_ALERT = 'ACTIVE_POP_ALERT';
export const RESET_SEARCH_PARAMS = 'RESET_SEARCH_PARAMS'; // 重置搜索条件
export const ACTIVE_UPLOAD_POP_ALERT = 'ACTIVE_UPLOAD_POP_ALERT'; // 打开上传符号表弹框
export const INACTIVE_UPLOAD_POP_ALERT = 'INACTIVE_UPLOAD_POP_ALERT'; // 关闭上传符号表弹框
export const ACTIVE_APPLY_POP_ALERT = 'ACTIVE_APPLY_POP_ALERT'; // 打开产品激活申请弹出框
export const UNACTIVE_APPLY_POP_ALERT = 'UNACTIVE_APPLY_POP_ALERT'; // 关闭产品激活弹出框
// 修改上传符号表提示信息
export const CHANGE_UPLOAD_POP_ALERT_INFO = 'CHANGE_UPLOAD_POP_ALERT_INFO';
export const RESET_SYMBOL_DATA = 'RESET_SYMBOL_DATA';

// app相关的action
export const APP_LIST_SUCC = 'APP_LIST_SUCC';
export const SELECT_APP = 'SELECT_APP';
export const INITIAL_CURRENTAPP = 'INITIAL_CURRENTAPP';
export const VERSION_LIST_SUCC = 'VERSION_LIST_SUCC';
export const HAS_GRAY_SUCC = 'HAS_GRAY_SUCC';
export const UPDATE_CURRENT_APP = 'UPDATE_CURRENT_APP';
export const APPLY_ACTIVE_SUCCESS = 'APPLY_ACTIVE_SUCCESS';
export const APPLY_ACTIVE_INIT = 'APPLY_ACTIVE_INIT';
export const SEND_MESSAGE_SUCC = 'SEND_MESSAGE_SUCC';
export const UPDATE_APP_FAVORITE_BY_ID = 'UPDATE_APP_FAVORITE_BY_ID';
// 问题列表actions
export const RESET_ISSUE_SEARCH_PARAMS = 'RESET_ISSUE_SEARCH_PARAMS';
export const TOGGLE_CRASH_DETAIL = 'TOGGLE_CRASH_DETAIL';
export const CHANGE_EXCEPTION_TYPE_OPTIONS = 'CHANGE_EXCEPTION_TYPE_OPTIONS';

// crash相关actions
export const GETSOLUTIONINFO_GET_SUCC = 'GETSOLUTIONINFO_GET_SUCC';

/**
 * 高级搜索
 */
export const SEARCH_LIST_SUCC = 'SEARCH_LIST_SUCC';
export const CHECK_ISSUE_ITEM = 'CHECK_ISSUE_ITEM';
export const SET_SEARCH_LIST = 'SET_SEARCH_LIST';
export const CHANGE_SEARCH_OPTIONS = 'CHANGE_SEARCH_OPTIONS';
export const SEARCH_OPTIONS_SUCC = 'SEARCH_OPTIONS_SUCC';
export const TAGITEM_ADD_SUCC = 'TAGITEM_ADD_SUCC';
export const TAGITEM_DEL_SUCC = 'TAGITEM_DEL_SUCC';
export const INIT_SEARCH_PARAMS = 'INIT_SEARCH_PARAMS';
export const INIT_ADVANCED_SEARCH_RESULT = 'INIT_ADVANCED_SEARCH_RESULT';

/**
 * 更多上报记录actions
 */
export const CRASHES_LIST_SUCC = 'CRASHES_LIST_SUCC';
export const IS_SHOW_CRASHITEM = 'IS_SHOW_CRASHITEM';

/**
 * 产品概览
 */
export const CHANGE_TREND_TAG = 'CHANGE_TREND_TAG';
export const CHANGE_RANK_TAG = 'CHANGE_RANK_TAG';
export const CHANGE_PAGE_TYPE = 'CHANGE_PAGE_TYPE';
export const GETSELECTOR_GET_SUCC = 'GETSELECTOR_GET_SUCC';
export const GET_TREND_SUCC = 'GET_TREND_SUCC';
export const CHANGE_TREND_PARAMS = 'CHANGE_TREND_PARAMS';
export const CHANGE_TOP3_STATUS = 'CHANGE_TOP3_STATUS';
export const GET_HOURLY_STAT_SUCC = 'GET_HOURLY_STAT_SUCC';
export const CHANGE_SUMMARY_VM_SELECT_VALUE = 'CHANGE_SUMMARY_VM_SELECT_VALUE';

export const APP_RATE_SUCC = 'APP_RATE_SUCC';
export const APP_ACCESS_SUCC = 'APP_ACCESS_SUCC';
export const SYSTEM_RATE_SUCC = 'SYSTEM_RATE_SUCC';
export const SYSTEM_ACCESS_SUCC = 'SYSTEM_ACCESS_SUCC';
export const MODEL_RATE_SUCC = 'MODEL_RATE_SUCC';
export const MODEL_ACCESS_SUCC = 'MODEL_ACCESS_SUCC';
export const TAG_RATE_SUCC = 'TAG_RATE_SUCC';
export const TAG_ACCESS_SUCC = 'TAG_ACCESS_SUCC';
export const GPU_RATE_SUCC = 'GPU_RATE_SUCC';
export const GPU_ACCESS_SUCC = 'GPU_ACCESS_SUCC';
export const CPU_RATE_SUCC = 'CPU_RATE_SUCC';
export const CPU_ACCESS_SUCC = 'CPU_ACCESS_SUCC';

export const REALTIMEAPPEND_FETCH_SUCC = 'REALTIMEAPPEND_FETCH_SUCC';
export const CHANGE_DATA_TYPE = 'CHANGE_DATA_TYPE';
export const CHANGE_TOPISSUEDATA_TYPE = 'CHANGE_TOPISSUEDATA_TYPE';
export const GET_APPEND_MINUTELY_STAT_SUCC = 'GET_APPEND_MINUTELY_STAT_SUCC';

/*
*事件统计
 */
export const REPORT_GET_SUCC = 'REPORT_GET_SUCC';

/*
*版本分布
 */
export const GETVERSIONTREND_GET_SUCC = 'GETVERSIONTREND_GET_SUCC';

export const GETCHANNELTREND_GET_SUCC = 'GETCHANNELTREND_GET_SUCC';

export const GETRETENTTREND_GET_SUCC = 'GETRETENTTREND_GET_SUCC';

export const CHANNELDATA_GET_SUCC = 'CHANNELDATA_GET_SUCC';

// 产品设置
export const GETMEMBERLIST_GET_SUCC = 'GETMEMBERLIST_GET_SUCC';

export const MEMBER_UPDATE_SUCC = 'MEMBER_UPDATE_SUCC';

export const MEMBER_DELETE_SUCC = 'MEMBER_DELETE_SUCC';

export const MEMBER_ADD_SUCC = 'MEMBER_ADD_SUCC';

// 通知订阅
export const DAILYCONFIG_GET_SUCC = 'DAILYCONFIG_GET_SUCC';

export const DAILYCONFIG_UPDATE_SUCC = 'DAILYCONFIG_UPDATE_SUCC';

// 新建产品
export const PRODUCT_INIT_SUCC = 'PRODUCT_INIT_SUCC';
export const PRODUCT_HASREPORT_SUCC = 'PRODUCT_HASREPORT_SUCC';
export const APPINFO_UPDATE_SUCC = 'APPINFO_UPDATE_SUCC';

export const CHANGE_STATE_SUCC = 'CHANGE_STATE_SUCC';

export const CHECK_IS_DEMO = 'CHECK_IS_DEMO';

export const SET_ERROR = 'SET_ERROR';
export const RESET_ERROR = 'RESET_ERROR';
export const SHOW_ERROR = 'show error';

// 授权
export const AUTH_INFO_SUCC = 'AUTH_INFO_SUCC';
export const AUTH_UNACCREDIT_SUCC = 'AUTH_UNACCREDIT_SUCC';
export const AUTH_ACCREDIT_SUCC = 'AUTH_ACCREDIT_SUCC';
export const AUTH_ACCREDIT_FAIL = 'AUTH_ACCREDIT_FAIL';
export const AUTH_INFO_FAIL = 'AUTH_INFO_FAIL';
export const AUTH_UNACCREDIT_FAIL = 'AUTH_UNACCREDIT_FAIL';
//
export const CHANGE_ISSUE_LIST_STATUS_CODE = 'CHANGE_ISSUE_LIST_STATUS_CODE';

export const UPDATE_STATE_PARAMS = 'UPDATE_STATE_PARAMS';

export const GETNEWSELECTOR_GET_SUCC = 'GETNEWSELECTOR_GET_SUCC';

export const SHOW_INVITATEPOP = 'SHOW_INVITATEPOP';

export const SOLUTION_INFO_SUCC = 'SOLUTION_INFO_SUCC';

export const CASE_APPROVE_SUCC = 'CASE_APPROVE_SUCC';

export const CASE_SOLUTION_SUCC = 'CASE_SOLUTION_SUCC';

export const SDK_VERSIONS_SUCC = 'SDK_VERSIONS_SUCC';

export const SUMMARY_INFO_SUCC = 'SUMMARY_INFO_SUCC';

export const SDK_LIST_SUCC = 'SDK_LIST_SUCC';

export const TOP_VERSIONS_SUCC = 'TOP_VERSIONS_SUCC';

export const CRASH_SDK_LIST_SUCC = 'CRASH_SDK_LIST_SUCC';

export const TOP_CRASH_VERSIONS_SUCC = 'TOP_CRASH_VERSIONS_SUCC';

export const SDK_APP_BUNDLES_SUCC = 'SDK_APP_BUNDLES_SUCC';

export const SDK_APP_LIST_SUCC = 'SDK_APP_LIST_SUCC';

export const TOP_APP_SUCC = 'TOP_APP_SUCC';

export const HAS_PRIORITY_SUCC = 'HAS_PRIORITY_SUCC';

// Symbol
export const SYMBOL_LIST_SUCC = 'SYMBOL_LIST_SUCC';


// keyword
export const CHANGE_KEYWORD_SEARCH_PARAM = 'CHANGE_KEYWORD_SEARCH_PARAM';
export const KEYWORDTREND_GET_SUCC = 'KEYWORDTREND_GET_SUCC';
export const GETVERSIONS_GET_SUCC = 'GETVERSIONS_GET_SUCC';
export const KEYWORDINFO_GET_SUCC = 'KEYWORDINFO_GET_SUCC';
export const KEYWORDINFO_POST_SUCC = 'KEYWORDINFO_POST_SUCC';
export const KEYWORDINFO_PUT_SUCC = 'KEYWORDINFO_PUT_SUCC';
export const KEYWORDINFO_DELETE_SUCC = 'KEYWORDINFO_DELETE_SUCC';

// ft
export const UPDATE_FTLIST_SIZE = 'UPDATE_FTLIST_SIZE';
export const UPDATE_STATE_STATUS = 'UPDATE_STATE_STATUS';
export const UPDATE_PAEG_NUM = 'UPDATE_PAEG_NUM';

// 获取公司架构
export const DEPARTMENT_LIST_REQ = 'DEPARTMENT_LIST_REQ';
export const DEPARTMENT_LIST_SUCC = 'DEPARTMENT_LIST_SUCC';
export const DEPARTMENT_LIST_FAIL = 'DEPARTMENT_LIST_FAIL';

export const DEPARTMENT_ITEM_REQ = 'DEPARTMENT_ITEM_REQ';
export const DEPARTMENT_ITEM_SUCC = 'DEPARTMENT_ITEM_SUCC';
export const DEPARTMENT_ITEM_FAIL = 'DEPARTMENT_ITEM_FAIL';

// tapd
export const TAPD_ID_SUCC = 'TAPD_ID_SUCC';
export const TAPD_ID_FAIL = 'TAPD_ID_FAIL';
export const TAPD_USERS_SUCC = 'TAPD_USERS_SUCC';
export const TAPD_SUBMIT_SUCC = 'TAPD_SUBMIT_SUCC';

export const UPDATE_VERSION_PARAMS = 'UPDATE_VERSION_PARAMS';
export const UPDATE_CHANNEL_PARAMS = 'UPDATE_CHANNEL_PARAMS';

export const UPDATE_APP_INFO = 'UPDATE_APP_INFO';
