import { fromJS, Map, Record } from 'immutable';
import { getFromLocal } from 'utils/Tool';
import { LEFTMENU_STATE } from 'utils/constants';
import { merge } from "lodash";
import { getInitialStorage } from 'utils/useCrashSightLocalStorage';

export const ReportConfig = new Record({
  appId: '',
  pid: '',
  platformId: '',
  crashRate: undefined,
  fluctuate: undefined, // 波动配置
  realtimeEnable: 0, // 是否启动实时统计
  receivers: '', // 告警接收人
  newUseridReceivers: '',
  crashImei: 0, // 影响的用户数
  maxAlert: 0, // 每天最多告警条数
  reportUrl: '',
  alertType: '',
  receiversNotInProj: '', // 其他接收告警的邮箱
  workweixinBotUrl: null, // 企业微信群机器人告警回调地址
  otherInfo: {},
});

function getInitialLocalStorage() {
  let initialStorage = getInitialStorage();

  const CRASHSIGHT_LOCAL_STORAGE_KEY = 'CrashSight';

  try {
    const localStorageItem = window.localStorage.getItem(CRASHSIGHT_LOCAL_STORAGE_KEY);
    if (localStorageItem) {
      merge(initialStorage, JSON.parse(localStorageItem));
    }
  } catch (e) {
    console.error(e);
  }
  return initialStorage;
}

export const initialState = fromJS({
  loading: true,
  dsymsSize: '', // 符号表管理的符号表大小
  popAlertData: new Map({
    issueId: '',
    processor: '',
    members: [],
    status: '',
  }),
  /**
   * 告警配置数据
   */
  reportConfig: [],
  /**
   * 左侧菜单fixed状态
   * 从localstorage中取值来初始化
   */
  leftMenuState: getFromLocal(LEFTMENU_STATE) ? getFromLocal(LEFTMENU_STATE) === 'true' : true,
  /**
   * 是否是demo产品
   */
  isDemoApp: false,
  error: new Map({
    hasError: false,
    status: 0,
    message: '',
  }),

  uploadPopAlertStatus: false,
  uploadPopAlertData: new Map({
    appId: '',
    pid: '',
    type: 1, // 1 Java, 2 dsym, 3 SO, 10 pdb,
    appKey: '',
    userId: '',
    uuid: '',
    tip: null,
  }),

  applyPopAlertStatus: false,
  applyPopAlertData: new Map({
    appId: '',
    pid: '',
    userId: '',
  }),

  departments: fromJS([]),
  subDepartments: fromJS({}),
  rows: Number(window.localStorage.getItem('pageRows') || 10),

  localStorage: getInitialLocalStorage(),
  /** 是否显示公告 */
  isAnnouncementShown: false,
});
