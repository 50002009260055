import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Button, Divider, Input, message, Radio, TreeSelect } from 'antd';
import { useTranslation } from 'react-i18next';
import isEqual from 'lodash/lang/isEqual';
import { CsTreeSelect } from 'components/antd-extension/CsTreeSelect';
import { DownOutlined } from '@ant-design/icons';
import { isNullish } from 'utils/nullish';
import uniq from 'lodash/array/uniq';
import { VersionUtil } from 'utils/version-util';

function findNodeFromTreeData(treeData, key) {
  if (!treeData) {
    return null;
  }
  for (let i = 0; i < treeData.length; i++) {
    if (treeData[i].key === key) {
      return treeData[i];
    }
    if (treeData[i].children?.length > 0) {
      const foundNode = findNodeFromTreeData(treeData[i].children, key);
      if (foundNode) {
        return foundNode;
      }
    }
  }
  return null;
}

function findMatchedChildNodeList(treeNode, searchText) {
  if (!treeNode) {
    return [];
  }
  if (treeNode.key?.includes(searchText)) {
    return [treeNode];
  }
  if (!treeNode.children?.length) {
    return [];
  }
  const allChildrenMatches = treeNode.children.every(x => x.key?.includes(searchText));
  if (allChildrenMatches) {
    return [treeNode];
  } else {
    const allDescendantMatches = treeNode.children
      .map(x => findMatchedChildNodeList(x, searchText))
      .flat();
    const isDescendantsSameAsChildren = treeNode.children.length === allDescendantMatches.length
      && treeNode.children.every((x, i) => x.key === allDescendantMatches[i].key);
    if (isDescendantsSameAsChildren) {
      return [treeNode];
    } else {
      return allDescendantMatches;
    }
  }
}

function getKeyListFromTreeDataFilteredBySearchText(treeData, checkedKey, searchText) {
  const checkedNode = findNodeFromTreeData(treeData, checkedKey);
  if (!checkedNode) {
    return [];
  }
  // console.log('gomo checkedNode = ', checkedNode);
  const nodeList = findMatchedChildNodeList(checkedNode, searchText);
  const keyList = nodeList.map(x => x.key);
  // console.log('gomo result keyList = ', keyList);
  return keyList;
}

const VersionHybridSelect = (props) => {
  const { t } = useTranslation();
  const {
    singleSelectMode: nullableSingleSelectMode,
    treeData: nullableTreeData,
    value: nullableValue,
    style,
    size,
    selectComponentSetting,
  } = props;

  const singleSelectMode = !!nullableSingleSelectMode;

  const treeData = nullableTreeData || [];
  const value = singleSelectMode ? nullableValue : (nullableValue || []);

  let {
    onChange,
    onDropdownVisibleChange,
    onChangeWhenDropdownClosed,
  } = props;

  onChange = onChange || (() => {});
  onDropdownVisibleChange = onDropdownVisibleChange || (() => {});
  onChangeWhenDropdownClosed = onChangeWhenDropdownClosed || (() => {});

  const [treeSelectSearchText, setTreeSelectSearchText] = useState('');

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [intermediateValue, setIntermediateValue] = useState(undefined);

  useEffect(() => {
    if (!isEqual(value, intermediateValue)) {
      setIntermediateValue(value);
    }
  }, [value]);

  /**
   * antd的TreeSelect，如果输入了搜索内容，点击亲节点的时候，效果也是选中整颗树的所有节点。
   * 现在需求是输入了过滤内容，选择某个亲节点，只选中亲节点下面所有满足过滤条件的节点
   * */
  function handleChangeValue(oldValue, newValueWithoutFilter, extra, searchText) {
    oldValue = oldValue || [];
    if (isNullish(extra?.checked) || isNullish(extra?.triggerValue)) {
      console.error('antd TreeSelect, invalid extra params!');
      return newValueWithoutFilter;
    }
    if (!(searchText || '').trim()) {
      return newValueWithoutFilter;
    }
    const { triggerValue } = extra;
    if (!triggerValue.endsWith(VersionUtil.WILDCARD_CHARACTER)) { // 选择的版本不带通配符，就不需要考虑过滤逻辑
      return newValueWithoutFilter;
    }
    // 如果输入了搜索内容，如果选择的是亲节点，需要遍历一遍选项树，只选出包含过滤内容的节点
    const selectedKeyList = getKeyListFromTreeDataFilteredBySearchText(treeData, triggerValue, searchText);
    // console.log('gomo, old = ', oldValue, 'selected = ', selectedKeyList);
    // 判断是要选择还是取消选择，如果keyList都在oldValue中，就取消选择，否则就选择
    const oldValueSet = new Set(oldValue);
    const isRemoveNotAdd = selectedKeyList.every(x => oldValueSet.has(x));
    if (isRemoveNotAdd) {
      const selectedKeySet = new Set(selectedKeyList);
      return oldValue.filter(x => !selectedKeySet.has(x));
    } else {
      // selectedKeyList里面可能含有通配符，需要一个一个检查
      const oldValueWithoutDup = oldValue.filter(old => {
        return selectedKeyList.some(sel => {
          if (sel.endsWith(VersionUtil.WILDCARD_CHARACTER)) {
            return !old.startsWith(sel.substring(0, sel.length - VersionUtil.WILDCARD_CHARACTER.length));
          } else {
            return old !== sel;
          }
        });
      });
      // console.log('gomo, old = ', oldValue, 'selected = ', selectedKeyList, 'oldValueWithoutDup = ', oldValueWithoutDup);
      return [...oldValueWithoutDup, ...selectedKeyList];
    }
  }

  return <CsTreeSelect
    size={size}
    style={style}
    showSearch={true}
    allowClear={true}
    treeData={treeData}
    treeCheckable={!singleSelectMode}
    showCheckedStrategy={'SHOW_PARENT'}
    placeholder={ t('EXCP_OVERVIEW.allVersion') }
    listHeight={500}
    treeDefaultExpandedKeys={ singleSelectMode ? [intermediateValue] : intermediateValue }
    value={ intermediateValue }
    showArrow={true}
    suffixIcon={<DownOutlined />}
    onChange={(v, label, extra) => {
      let newV = v;
      try {
        newV = handleChangeValue(intermediateValue, v, extra, treeSelectSearchText);
      } catch (e) { // 发生异常则走antd TreeSelect默认逻辑
        console.error('VersionHybridSelect handleChangeValue throws exception,', e);
        message.error('An error occurred when filtering options');
      }
      if (dropdownOpen) {
        setIntermediateValue(newV);
      } else {
        onChangeWhenDropdownClosed(newV);
      }
      onChange(newV);
    }}
    searchValue={treeSelectSearchText}
    onSearch={(v) => {
      setTreeSelectSearchText(v);
    }}
    onKeyDown={(e) => {
      if (e.key !== 'Enter' || singleSelectMode) {
        return;
      }
      // 可以通过按下回车键直接确认选择一个任意字串作为版本的逻辑
      const newItem = treeSelectSearchText;
      setTreeSelectSearchText('');
      if (newItem && !value.includes(newItem)) {
        const newValue = [...value, newItem];
        onChange(newValue);
        onChangeWhenDropdownClosed(newValue);
      }
    }}
    onDropdownVisibleChange={(open) => {
      setDropdownOpen(open);
      if (open) {
        setIntermediateValue(value);
      } else if (value !== intermediateValue) {
        onChangeWhenDropdownClosed(intermediateValue);
      }
      onDropdownVisibleChange(open);
    }}
    {...selectComponentSetting}
  />;
};

VersionHybridSelect.propTypes = {
  singleSelectMode: PropTypes.bool,
  treeData: PropTypes.array.isRequired,
  value: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  onChange: PropTypes.func,
  onDropdownVisibleChange: PropTypes.func,
  onChangeWhenDropdownClosed: PropTypes.func,
};

export default VersionHybridSelect;
