import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import _style from './style.scss';
import { Button, Popover, Space, Radio, Table, Select, Tooltip, Checkbox, Modal } from 'antd';
import { withTranslation } from 'react-i18next'
import { red } from '@ant-design/colors';
import { ze } from 'utils/zhEn';
import { connect, useSelector } from 'react-redux';
import { IssueListTrendTypeEnum } from 'utils/constants';
import { DownloadOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { DemoUtil } from 'utils/demo-util';
import reportEvent, { EVENT_ACTIONS, getPageTitle } from 'utils/reportEvent';
import WrappedTipsIcon from 'components/antd-extension/WrappedTipsIcon.jsx';
import { EXCEPTION_TYPE_INT_ANDROID_JAVA, EXCEPTION_TYPE_INT_ANDROID_NATIVE, getReportCategoryByExceptionTypeInt } from 'utils/constants/exception-type-int';
import { ExceptionCategoryUtil } from 'utils/exception-category';
import { ServerAppSettings } from 'utils/server-app-settings';
import { selectHasSpecifiedPermissionInCurrentApp } from 'utils/selectors/selectors';
import { RolePermission } from 'utils/constants/role-permission';

const SearchParamToTableParam = {
  'desc': 'descend',
  'asc': 'ascend',
};

/**
 * 问题列表头部视图
 */
@connect((state) => {
  return {
    reduxState: state,
  };
}, (dispatch) => ({ dispatch }))
@withTranslation()
export default class ListHeader extends Component {
  constructor(props) {
    super(props);
    let { sortField, sortOrder } = props.initialSortData;
    // 初始化排序状态
    if (!sortField) {
      sortField = 'uploadTime';
      sortOrder = 'desc';
    }
    this.state = Object.assign({
      imeiCount: true,
      crashCount: true,
      crashCountConditionA: true,
      crashCountConditionB: true,
      uploadTime: true,
      lastTime: 0,
      flag: false,
      isShowMergeIssuesModal: false,
    }, {
      [sortField]: SearchParamToTableParam[sortOrder] ?? null, // 初始化排序状态
    });
  }

  handleCheck(checked) {
    const { checkIssue } = this.props;
    checkIssue({ checkAll: true, checked });
  }

  handleClick(field, sortOrder) {
    const now = new Date().getTime();

    if ((now - this.state.lastTime) < 500) return;

    this.props.onChangeSearchParam({
      sortField: field,
      sortOrder: sortOrder,
    });

    const allFields = ['imeiCount', 'crashCount', 'uploadTime', 'crashCountConditionA', 'crashCountConditionB'];
    allFields.map((item) => {
      if (item === field) {
        this.setState({
          [item]: !this.state[item],
          lastTime: now,
        });
      } else {
        this.setState({
          [item]: true,
          lastTime: now,
        });
      }
      return item;
    });
  }

  handleTableChange(tableEvent) {
    console.log("=== tableEvent ===", tableEvent);
    if (tableEvent.sorter) {
      this.handleClick(tableEvent.sorter.field, tableEvent.sorter.order === 'ascend' ? 'asc' : 'desc');
    }
  }

  render() {
    const {
      reduxState,
      actions,
      hasCheckAll, checkedNum, totalRows, style, initialSortData, changeState,
      demoApp, onClickUpsertTapdBugByIssueIdList, checkedIssues, currentApp,
      isMultiVersionSearch, onClickMergeIssues,
      isCompareSearch, exceptionType,
      queryInaccurateReason,
      fixedHeight,
      t,
    } = this.props;

    const { issueListTrendType } = reduxState.global.get('localStorage').toJS();

    const isMultiVersionOrCompareSearch = isMultiVersionSearch || isCompareSearch;

    const allowViewDashboard = selectHasSpecifiedPermissionInCurrentApp(reduxState, RolePermission.VIEW_DASHBOARD);
    const hasEditPermission = selectHasSpecifiedPermissionInCurrentApp(reduxState, RolePermission.EDIT);

    const { appId, pid } = currentApp.toJS();
    const isTapdEnabled = process.env.REACT_APP_USE_TAPD
    const ifShowMergeBtn = ExceptionCategoryUtil.isCrash(ExceptionCategoryUtil.unify(exceptionType));

    const serverAppSettings = reduxState.app.get('appIdToServerAppSettings')[appId] || {};
    const enableIssueMerge = serverAppSettings[ServerAppSettings.keys.enableIssueMerge];

    const queryInaccurateReasonDom = queryInaccurateReason && <Space style={{ marginTop: '8px', color: red.primary }}>
      <ExclamationCircleOutlined/>
      <div>{ t("BASEPROBLEM.ISSUE_LIST_STEP_1_QUERY_CROSS_VER_OVERFLOW") }</div>
    </Space>;

    const columns = [
      {
        title: <span style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Checkbox checked={hasCheckAll} onChange={(e) => this.handleCheck(e.target.checked)}></Checkbox>
        </span>,
        dataIndex: 'check',
        width: 42,
      },
      {
        title: <span className={classnames('main-content', { 'has-checked': checkedNum > 0 })}>
          { checkedNum > 0 && <Button className={_style.checked_fun_btn} style={{ marginRight: '20px' }} onClick={() => { (!DemoUtil.checkAndWarnIfDemoApp(demoApp) && changeState) && changeState(); }}>{ this.props.t("BASEPROBLEM.stateChange") }</Button> }
          { isTapdEnabled && checkedNum > 0 && <Button className={_style.checked_fun_btn} style={{ marginRight: '20px' }} onClick={() => !DemoUtil.checkAndWarnIfDemoApp(demoApp) && onClickUpsertTapdBugByIssueIdList(checkedIssues)}>{this.props.t('issueCrashFilterKey.createBugForm')}</Button>}
          { ifShowMergeBtn && checkedNum > 1 && enableIssueMerge && hasEditPermission && <Button className={_style.checked_fun_btn} style={{ marginRight: '20px' }} onClick={() => { (!DemoUtil.checkAndWarnIfDemoApp(demoApp)) && onClickMergeIssues && onClickMergeIssues(checkedIssues)}}>{ze('合并问题', 'Merge Issues')}</Button> }
          { checkedNum > 0 && (
            <span>
              { this.props.t("BASEPROBLEM.checked") }:{` ${checkedNum}`}
            </span>
          )}
          { checkedNum === 0 && (
            <span>
              { this.props.t("BASEPROBLEM.problem") }
              {` (${totalRows || '0'})`}
            </span>
          )}
        </span>,
        align: 'left',
      },
      {
        title: allowViewDashboard && <span style={{ float: 'right', width: '200px', fontSize: '13px', display:"flex" }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div style={{ lineHeight: '26px', marginRight: 4 }}>{ze('最近上报趋势', 'Report Trend')}</div>
            <Select
              style={{ width: 90, fontSize: '14px',textAlign: 'left' }}
              options={[
                {label: ze('24小时', '24h'), value: IssueListTrendTypeEnum.HOURLY},
                {label: ze('14天', '14d'), value: IssueListTrendTypeEnum.DAILY},
                {label: ze('隐藏', 'Hide'), value: IssueListTrendTypeEnum.NONE},
              ]}
              value={issueListTrendType}
              onChange={(v) => {
                actions.setPartialLocalStorage({ issueListTrendType: v });
                console.log('setPartialLocalStorage', v)
                reportEvent({
                  action: EVENT_ACTIONS.CLICK,
                  tp1: `${getPageTitle()}-最近上报趋势`,
                  tp6: v,
                })
              }}
            />
          </div>
        </span>,
        align: 'center',
        width: 200,
      },
      {
        key: 'uploadTime',
        title: <div style={{ maxWidth: 125, textAlign: 'right', whiteSpace: 'pre' }}>
          { this.props.t("BASEPROBLEM.post") }
        </div>,
        dataIndex: 'uploadTime',
        align: 'center',
        sorter: true,
        defaultSortOrder: this.state['uploadTime'],
        width: 110,
      },
      {
        title: <span style={{ minWidth: 95, whiteSpace: 'pre' }}>
          { this.props.t("CRASHDETAIL.totalReports") }
        </span>,
        dataIndex: 'crashCount',
        align: 'center',
        sorter: true,
        defaultSortOrder: this.state['crashCount'],
        width: 110,
      },
      {
        title: <span
          style={isMultiVersionOrCompareSearch ? { maxWidth: 130, whiteSpace: 'pre' } : { maxWidth: 105, whiteSpace: 'pre' }}
          onClick={() => {
            if (!isMultiVersionOrCompareSearch) {
              // this.handleClick('imeiCount');
            }
          }}
        >
          <div style={{display:'flex',gap:'2px'}}>
            { this.props.t("CRASHDETAIL.totalDevices") }
            {isMultiVersionOrCompareSearch &&
              <Tooltip
              title={(<div>{ ze('对比查询、多版本查询不支持统计影响用户数', 'Compare queries and multi-version queries do not support the statistics of affected users.') }</div>)}
            >
              <WrappedTipsIcon />
            </Tooltip>
            }
          </div>
        </span>,
        align: 'center',
        dataIndex: 'imeiCount',
        sorter: !isMultiVersionOrCompareSearch,
        defaultSortOrder: this.state['imeiCount'],
        width: 130,
      },
      {
        title: ze("状态", "Status"),
        align: 'center',
        width: 140,
      },
    ];

    return <div className={_style.header_fixed} style={{ top: fixedHeight }}>
      { queryInaccurateReasonDom }
      {<Table
        columns={columns} dataSource={[]}
        sortDirections={["descend", "ascend"]}
        pagination={false} className={_style.hide_content} showSorterTooltip={false}
        onChange={(pagination, filters, sorter, extra) => this.handleTableChange({ pagination, filters, sorter, extra })}
      />}
    </div>;
  }
}

ListHeader.propTypes = {
  initialSortData: PropTypes.object.isRequired,
  hasCheckAll: PropTypes.bool.isRequired,
  demoApp: PropTypes.bool.isRequired,
  checkedNum: PropTypes.number.isRequired,
  checkIssue: PropTypes.func.isRequired,
  totalRows: PropTypes.number.isRequired,
  exceptionType: PropTypes.number,
  queryInaccurateReason: PropTypes.string,
  style: PropTypes.object,
  onChangeSearchParam: PropTypes.func.isRequired,
  changeState: PropTypes.func,
  onClickMergeIssues: PropTypes.func,
  onClickUpsertTapdBugByIssueIdList: PropTypes.func,
  currentApp: PropTypes.object.isRequired,
  isMultiVersionSearch: PropTypes.bool,  // 是否是多版本查询
  isCompareSearch: PropTypes.bool,  // 是否是对比查询
  fixedHeight: PropTypes.bool,
};
