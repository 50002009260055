import React, { useState, useEffect, useMemo } from 'react';
import { bindActionCreators } from 'redux';
import { connect, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import CardHead from 'components/commons/CardHead';
import { Button, Checkbox, Col, DatePicker, Row, Select, Space, Spin, Table, Tooltip, Switch } from 'antd';
import moment from 'moment';
import { PAGE_TYPE_CONVERT, SummaryVmOptions } from 'utils/constants';
import groupBy from 'lodash/collection/groupBy';
import { useTranslation } from 'react-i18next';
import { countryI18n, CountryUtil, countryFlagUrl } from 'utils/country';
import { formatEnNumber } from 'utils/format/format-number';
import CountrySelect from 'components/exception/ProductSummaryPage/CountrySelect';
import { useZhEn } from 'utils/react-hooks/useZhEn';
import uniq from 'lodash/array/uniq';
import { WorldMapChart } from './WorldMapChart';
import { Tab } from 'components/exception/issue';
import XLSX from 'xlsx';
import style from 'components/exception/ProductSummaryPage/style.scss';
import { getSummaryTags } from 'utils/StringUtils';
import { selectCurrentAppDatePickerAvailableMomentRange } from 'utils/selectors/selectors';
import reportEvent, { EVENT_ACTIONS } from 'utils/reportEvent';
import CountryIcon from 'svg/v2/newcs_dashboard_overview_statsbyregion_icon.svg';
import CsDownloadButton from 'components/commons/CsDownloadButton/CsDownloadButton';
import CsCollapseButton from 'components/commons/CsCollapseButton/CsCollapseButton';
import WrappedTipsIcon from 'components/antd-extension/WrappedTipsIcon.jsx';
import CsTabs from 'components/antd-extension/CsTabs';
import _style from './StatByCountry.scss';
import { UpdateTimeTag } from './UpdateTimeTag';

const { RangePicker } = DatePicker;

const StatByCountry = (props) => {
  const { t } = useTranslation();
  const { ze } = useZhEn();

  const [
    datePickerMinAvailableMoment,
    datePickerMaxAvailableMoment,
  ] = useSelector(selectCurrentAppDatePickerAvailableMomentRange);

  const {
    reduxState,
    forceFetchTrigger,
  } = props;
  const currentApp = reduxState.app.get('current').toJS();
  const summary = reduxState.summary.toJS();
  const bigDataRealtimeUpdateMillis = reduxState.summary.get('bigDataRealtimeUpdateMillis');
  const { appId, platformId } = currentApp;
  const { searchParams, selectOptions } = summary;
  const { pageType, rankVersion } = reduxState.summary.get('selectOptions').toJS();
  const reportCategory = PAGE_TYPE_CONVERT[pageType];
  const versionOptions = rankVersion.options;

  const [isExpanded, setIsExpanded] = useState(false);
  const [loading, setLoading] = useState(false);
  const [countryList, setCountryList] = useState([]);
  const [alsoQueryCountriesInCustomArea, setAlsoQueryCountriesInCustomArea] = useState(true);
  const [version, setVersion] = useState(null);
  const [vmStatus, setVmStatus] = useState(0);
  const [minDate, setMinDate] = useState(moment().format('YYYY-MM-DD'));
  const [maxDate, setMaxDate] = useState(moment().format('YYYY-MM-DD'));
  const [countryStatList, setCountryStatList] = useState([]);
  const [isMultiDayResult, setIsMultiDayResult] = useState(false);
  const [fetchTrigger, setFetchTrigger] = useState(0);

  const [isDisplayMap, setIsDisplayMap] = useState(false);
  const [currentTab, setCurrentTab] = useState('EXCP_OVERVIEW.occurNumber');
  const [tabs, setTabs] = useState([]);
  const [tableDom, setTableDom] = useState();

  const tabsMap = {
    'EXCP_OVERVIEW.userCrashRate': 'devicePercentage',
    'EXCP_OVERVIEW.occurNumCrashRate': 'numPercentage',
    'EXCP_OVERVIEW.occurNumber': 'crashNum',
    'EXCP_OVERVIEW.userNumAffect': 'crashUser',
    'EXCP_OVERVIEW.sessionCount': 'accessNum',
    'EXCP_OVERVIEW.userNumConnect': 'accessUser',
    'EXCP_OVERVIEW.userANRRate': 'devicePercentage',
    'EXCP_OVERVIEW.occurNumANRRate': 'numPercentage',
    'EXCP_OVERVIEW.userErrorRate': 'devicePercentage',
    'EXCP_OVERVIEW.occurNumErrorRate': 'numPercentage',
    'EXCP_OVERVIEW.sessionCrashRate': 'sessionPercentage',
    'EXCP_OVERVIEW.sessionNumAffect': 'crashSession',
  };
  const notDisplayMutilDay = ['EXCP_OVERVIEW.userCrashRate', 'EXCP_OVERVIEW.userANRRate', 'EXCP_OVERVIEW.userErrorRate', 'EXCP_OVERVIEW.userNumAffect', 'EXCP_OVERVIEW.userNumConnect'];

  // 切换pageType时设置对应的tabs数组
  useEffect(() => {
    const tabs = getSummaryTags(selectOptions.pageType, searchParams.pid);
    setTabs(tabs);
  }, [searchParams.pid, selectOptions.pageType]);

  // 切换app时重置搜索字段
  useEffect(() => {
    if (!appId) {
      return;
    }
    setCountryList([]);
    setVersion(null);
    setMinDate(moment().format('YYYY-MM-DD'));
    setMaxDate(moment().format('YYYY-MM-DD'));
    setFetchTrigger(fetchTrigger + 1);
  }, [appId]);
  useEffect(() => {
    if (!appId) {
      return;
    }
    setFetchTrigger(fetchTrigger + 1);
  }, [pageType]);

  useEffect(() => {
    if (!fetchTrigger) {
      return;
    }
    fetchTrend();
  }, [fetchTrigger]);

  useEffect(() => {
    if (!forceFetchTrigger) {
      return;
    }
    fetchTrend();
  }, [forceFetchTrigger]);

  // 当前tab状态变化时切换对应的tableDOM
  useEffect(() => {
    if (!isDisplayMap) {
      setTableDom(allColumnsTableDom);
      return;
    }
    setTableDom(tablesArr.filter(table => table.key === currentTab)[0]);
    // 选择多日数据时，如果当前tab仍在不可展示项则切换至'发生次数'tab
    if (isMultiDayResult && notDisplayMutilDay.includes(currentTab)) {
      setCurrentTab('EXCP_OVERVIEW.occurNumber');
    }
  }, [currentTab, isDisplayMap, countryStatList, isMultiDayResult]);

  async function fetchTrend() {
    setLoading(true);
    let queryCountryList = countryList;
    if (alsoQueryCountriesInCustomArea) {
      const countryGroupConfigList = reduxState.summary.get('countryGroupConfigList').toJS();
      const prefixedIdToCountries = Object.assign({}, ...countryGroupConfigList.map(x => ({ [CountryUtil.COUNTRY_GROUP_PREFIX + x.id]: x.countries })));
      queryCountryList = uniq([
        ...countryList,
        ...countryList.map(x => prefixedIdToCountries[x] || [x]).flat(),
      ]);
    }

    const startDate = minDate.replace(/-/g, '');
    const endDate = maxDate.replace(/-/g, '');
    const rsp = await RestHelper.mustPost('/api/app/getTrend', {
      appId,
      platformId,
      subModuleId: searchParams.subModuleId,
      version: version || '-1',
      startDate,
      endDate,
      type: reportCategory,
      vm: vmStatus,
      needCountryDimension: true,
      countryList: queryCountryList,
    });
    const trendDataList = CountryUtil.convertTrendCountryGroupIdToName(reduxState.summary, rsp.json.ret.data);
    // 多个日期的数据合并（GET_TREND_SUCC回调那边有类似逻辑，后面可以抽取成公共的）
    const countryToDateTrendList = groupBy(trendDataList, x => x.country);
    const countryStatList = Object.entries(countryToDateTrendList).map(([country, dataTrendList]) => dataTrendList.reduce((acc, x) => {
      if (!acc) {
        return x;
      }
      return {
        ...acc,
        accessNum: x.accessNum + acc.accessNum,
        accessUser: x.accessUser + acc.accessUser,
        crashNum: x.crashNum + acc.crashNum,
        crashSession: x.crashSession + acc.crashSession,
        crashUser: x.crashUser + acc.crashUser,
      };
    }, null));
    const enrichedCountryStatList = countryStatList
      .map((x) => {
        let { country } = x;
        if (['0', '1'].includes(country)) {
          country = `Unknown (${country})`;
        }
        const devicePercentage = x.accessUser > 0
          ? (100 * x.crashUser / x.accessUser)
          : 0;
        const numPercentage = x.accessNum > 0
          ? (100 * x.crashNum / x.accessNum)
          : 0;
        const sessionPercentage = x.accessNum > 0
          ? (100 * x.crashSession / x.accessNum)
          : 0;
        return {
          ...x,
          country,
          devicePercentage,
          sessionPercentage,
          numPercentage,
        };
      })
      .filter(x => x.country !== '-');
    setCountryStatList(enrichedCountryStatList);
    if (countryStatList.length && !isExpanded) {
      setIsExpanded(true);
    }
    setIsMultiDayResult(startDate !== endDate);

    setLoading(false);
  }

  const multiDayNotSupportedDom = <Tooltip
    title={ze(
      '多日查询不支持设备数相关指标（影响设备数、联网设备数等）',
      'If the selected time range spans multiple days and performance considerations are taken into account, some device-related metric queries may not be supported, such as "Affected devices" or "Active devices".',
    )}>
    <Space size={4}>
      <div>-</div>
      <WrappedTipsIcon />
    </Space>
  </Tooltip>;

  // 渲染列表的国家地区label，带上国旗图标和中英文名称
  const renderCountryLabelDom = (country, type) => {
    if (type) {
      return !CountryUtil.isDeprecated(country) ? `${countryI18n(country)}` : `${countryI18n(country)} ${ze('(已废弃)', '(Deprecated)')}`;
    }
    return <span style={{ display: 'flex', alignItems: 'center', height: 16 }}>
      {countryFlagUrl(country)
        ? (<img style={{ width: '20px', marginRight: '5px' }} alt="Icon" src={countryFlagUrl(country)} />)
        : (<span style={{ width: '20px', marginRight: '5px' }}> </span>)}
      {!CountryUtil.isDeprecated(country) ? `${countryI18n(country)}` : `${countryI18n(country)} ${ze('(已废弃)', '(Deprecated)')}`}
    </span>;
  };

  const allColumns = [{
    title: ze('国家或地区', 'Country/Region'),
    width: '200px',
    key: 'country',
    dataIndex: 'country',
    render: (text, record) => {
      const { country } = record;
      return country !== '-1'
        ? renderCountryLabelDom(country)
        : <Tooltip title={ze('设备数相关指标（影响设备数、联网设备数等）为所有设备去重之后的结果。因为可能有在多个国家上报消息的设备，所以相关指标会小于其他所有地区相加之和。', 'The metrics related to the number of devices (number of crashed devices, number of online devices, etc.) are the result of de-weighting all devices. Because there may be devices reporting messages in multiple countries, the relevant metric will be less than the sum of all other regions.')}>
            <Space size={4}>
              <div>{ t('EXCP_OVERVIEW.全部国家地区') }</div>
              <WrappedTipsIcon />
            </Space>
          </Tooltip>;
    },
    sorter: (a, b) => (a.country || '').localeCompare(b.country),
  }, ...['EXCP_OVERVIEW.userCrashRate', 'EXCP_OVERVIEW.userANRRate', 'EXCP_OVERVIEW.userErrorRate'].map(tab => ({
    title: t(tab),
    width: '200px',
    align: 'right',
    key: tab,
    dataIndex: 'devicePercentage',
    render: (text, record) => {
      if (isMultiDayResult) {
        return multiDayNotSupportedDom;
      }
      const { devicePercentage } = record;
      return `${devicePercentage.toFixed(2)} %`;
    },
    sorter: (a, b) => a.devicePercentage - b.devicePercentage,
  })), ...['EXCP_OVERVIEW.occurNumCrashRate', 'EXCP_OVERVIEW.occurNumANRRate', 'EXCP_OVERVIEW.occurNumErrorRate'].map(tab => ({
    title: t(tab),
    width: '200px',
    align: 'right',
    key: tab,
    dataIndex: 'numPercentage',
    render: (text, record) => {
      const { numPercentage } = record;
      return `${numPercentage.toFixed(2)} %`;
    },
    sorter: (a, b) => a.numPercentage - b.numPercentage,
  })), {
    title: t('EXCP_OVERVIEW.sessionCrashRate'),
    width: '200px',
    align: 'right',
    key: 'EXCP_OVERVIEW.sessionCrashRate',
    dataIndex: 'sessionPercentage',
    render: (text, record) => {
      const { sessionPercentage } = record;
      return `${sessionPercentage.toFixed(2)} %`;
    },
    sorter: (a, b) => a.sessionPercentage - b.sessionPercentage,
  }, {
    title: t('EXCP_OVERVIEW.occurNumber'),
    width: '200px',
    align: 'right',
    key: 'EXCP_OVERVIEW.occurNumber',
    dataIndex: 'crashNum',
    defaultSortOrder: 'descend',
    render: (text, record) => formatEnNumber(text),
    sorter: (a, b) => a.crashNum - b.crashNum,
  }, {
    title: t('EXCP_OVERVIEW.sessionNumAffect'),
    width: '200px',
    align: 'right',
    key: 'EXCP_OVERVIEW.sessionNumAffect',
    dataIndex: 'crashSession',
    render: (text, record) => {
      if (isMultiDayResult) {
        return multiDayNotSupportedDom;
      }
      return formatEnNumber(text);
    },
    sorter: (a, b) => a.crashSession - b.crashSession,
  }, {
    title: t('EXCP_OVERVIEW.userNumAffect'),
    width: '200px',
    align: 'right',
    key: 'EXCP_OVERVIEW.userNumAffect',
    dataIndex: 'crashUser',
    render: (text, record) => {
      if (isMultiDayResult) {
        return multiDayNotSupportedDom;
      }
      return formatEnNumber(text);
    },
    sorter: (a, b) => a.crashUser - b.crashUser,
  }, {
    title: t('EXCP_OVERVIEW.sessionCount'),
    width: '200px',
    align: 'right',
    key: 'EXCP_OVERVIEW.sessionCount',
    dataIndex: 'accessNum',
    render: (text, record) => formatEnNumber(text),
    sorter: (a, b) => a.accessNum - b.accessNum,
  }, {
    title: t('EXCP_OVERVIEW.userNumConnect'),
    width: '200px',
    align: 'right',
    key: 'EXCP_OVERVIEW.userNumConnect',
    dataIndex: 'accessUser',
    render: (text, record) => {
      if (isMultiDayResult) {
        return multiDayNotSupportedDom;
      }
      return formatEnNumber(text);
    },
    sorter: (a, b) => a.accessUser - b.accessUser,
  }];
  // 单独展示列表时TableDom
  const allColumnsTableDom = <Table
    size='small'
    columns={[allColumns[0], ...allColumns.filter(col => tabs.includes(col.key))]}
    key='allColumns'
    dataSource={countryStatList}
    showSorterTooltip={false}
    pagination={{
      showSizeChanger: true,
      defaultPageSize: 5,
      pageSizeOptions: [5, 10, 20, 50, 100],
    }}
    style={{ flex: 1 }}
  />;

  // table组件中defaultSortOrder属性只在第一次渲染时生效，组件挂载时预生成所有指标的TableDOM
  const tablesArr = allColumns.slice(1).map(col => [allColumns[0], { ...col, defaultSortOrder: 'descend' }])
    .map(columns => <Table
    size='small'
    columns={columns}
    key={columns[1].key}
    dataSource={countryStatList}
    pagination={countryStatList.length < 10 ? false : {
      showSizeChanger: false,
      pageSize: 15,
    }}
    style={{ flex: 1 }}
  />);

  function createExcel(data, title) {
    const { utils } = XLSX;
    const ws_name = 'sheet1';
    const wb = utils.book_new();
    /* make worksheet */
    const ws = utils.aoa_to_sheet(data);
    /* Add the worksheet to the workbook */
    XLSX.utils.book_append_sheet(wb, ws, ws_name);
    XLSX.writeFile(wb, title);
  }

  // 国家维度的数据下载
  const downloading = (e) => {
    e.stopPropagation();
    const header = [
      ze('国家或地区', 'Country/Region'),
      t('EXCP_OVERVIEW.userCrashRate'),
      t('EXCP_OVERVIEW.occurNumCrashRate'),
      t('EXCP_OVERVIEW.occurNumber'),
      t('EXCP_OVERVIEW.userNumAffect'),
      t('EXCP_OVERVIEW.sessionCount'),
      t('EXCP_OVERVIEW.userNumConnect'),
    ];
    const rows = countryStatList.map(item => [
      item.country !== '-1' ? renderCountryLabelDom(item.country, 'download') : t('EXCP_OVERVIEW.全部国家地区'),
      `${item.devicePercentage.toFixed(2)} %`,
      `${item.numPercentage.toFixed(2)} %`,
      formatEnNumber(item.crashNum),
      formatEnNumber(item.crashUser),
      formatEnNumber(item.accessNum),
      formatEnNumber(item.accessUser),
    ]);
    const filename = `${ze('按国家维度统计详情', 'Stats By Region/Country')}.xlsx`;
    createExcel([header, ...rows], filename);
  };

  const tabOptions = useMemo(() => {
    if (isMultiDayResult) return tabs.filter(tab => !notDisplayMutilDay.includes(tab)).map(tab => ({
      key: tab,
      label: t(tab),
    }));

    return tabs.map(tab => ({
      key: tab,
      label: t(tab),
    }));
  }, [isMultiDayResult, tabs]);

  return <Spin spinning={loading}>
    <CardHead
      title={ze('按国家维度统计详情', 'Stats By Region/Country')}
      hideDivider={!isExpanded}
      svgIcon={<CountryIcon />}
      onClick={() => {
        if (!isExpanded) {
          reportEvent({
            action: EVENT_ACTIONS.CLICK,
            tp1: '按国家维度统计详情',
          });
        }
        setIsExpanded(prev => !prev);
      }}
    >
      <div style={{ display: 'flex', flexGrow: '1', alignItems: 'center', justifyContent: 'flex-end' }}>
        <UpdateTimeTag updateTime={bigDataRealtimeUpdateMillis} />
        <CsDownloadButton
          style={{ margin: '0px 24px' }}
          onClick={(e) => downloading(e)}
        />
        {/* {
          isExpanded ? <UpOutlined onClick={() => setIsExpanded(false)} />
            : <DownOutlined onClick={() => {
              setIsExpanded(true);
              reportEvent({
                action: EVENT_ACTIONS.CLICK,
                tp1: '按国家维度统计详情',
              });
            }} />
        } */}

        <CsCollapseButton
          extraStyle={{ width: '14px', height: '14px' }}
          isCollapsed={!isExpanded}
          enableHover={true}
          onClick={(e) => {
            e.stopPropagation();
            if (!isExpanded) {
              reportEvent({
                action: EVENT_ACTIONS.CLICK,
                tp1: '按国家维度统计详情',
              });
            }
            setIsExpanded(!isExpanded);
          }}
        />
      </div>
      {/* <Row gutter={8} align='middle' style={{ flexGrow: 1 }}>
        <Col>
          <Select
            allowClear={true}
            showSearch
            style={{ width: '180px' }}
            options={versionOptions}
            placeholder={t('common.所有版本')}
            value={version}
            onChange={v => setVersion(v)}
          />
        </Col>
        <Col>
          <RangePicker
            style={{ width: '220px' }}
            disabledDate={moment => moment && (moment.isBefore(datePickerMinAvailableMoment) || moment.isAfter(datePickerMaxAvailableMoment))}
            allowClear={false}
            allowEmpty={[false, false]}
            showToday={false}
            placeholder={[ze('日期范围', 'Time Range')]}
            value={[moment(minDate), moment(maxDate)]}
            onChange={(dates) => {
              const [minDate, maxDate] = (dates || [null, null]).map(x => x && x.format('YYYY-MM-DD'));
              setMinDate(minDate);
              setMaxDate(maxDate);
            }}
          />
        </Col>
        <Col>
          <Select
            style={{ width: '120px' }}
            options={SummaryVmOptions}
            value={vmStatus}
            onChange={v => setVmStatus(v)}
          />
        </Col>
        <Col>
          <CountrySelect
            value={countryList}
            isMultiple={true}
            onChangeWhenDropdownClosed={(v) => setCountryList(v)}
          />
        </Col> */}
        { /*  空间太小，摆不下这个下拉框了，默认true  <Col>
          <Checkbox
            checked={alsoQueryCountriesInCustomArea}
            onChange={e => setAlsoQueryCountriesInCustomArea(e.target.checked)}
          >{ ze('额外查询组合地区包含的国家地区', 'Also Query Regions In Custom Areas') }</Checkbox>
        </Col> */ }
        {/* <Col>
          <Button
            type='primary'
            style={{marginLeft:'20PX',marginRight:'20px'}}
            onClick={() => {
              setIsExpanded(true);
              fetchTrend();
            }}
          >{ t('common.查询') }</Button>
           <Button
              onClick={() => { downloading() }}
              icon={<DownloadOutlined />}
            >
              {ze('下载数据', 'Download')}
            </Button>
        </Col>
        <Col style={{marginLeft: 'auto'}}>
          <Switch
            checkedChildren={ze('地图', 'Map')}
            unCheckedChildren={ze('列表', 'List')}
            checked={isDisplayMap}
            onClick={(checked) => setIsDisplayMap(checked)}
          />
        </Col>
        <Col>
          <Button
            type='link'
            onClick={() => {
              !isExpanded && reportEvent({
                action: EVENT_ACTIONS.CLICK,
                tp1: '按国家维度统计详情'
              })
              setIsExpanded(!isExpanded);
             }}
            style={{ paddingLeft: 0 }}
          >
            {isExpanded ? t("EXCP_OVERVIEW.collapse") : t("EXCP_OVERVIEW.expand")}
          </Button>
        </Col>
      </Row> */}
    </CardHead>
    { isExpanded && <div>
      <Row gutter={10} align='middle' style={{ margin: '20px -5px' }}>
        <Col>
          <Select
            allowClear={true}
            showSearch
            style={{ width: '320px' }}
            options={versionOptions}
            placeholder={t('common.所有版本')}
            value={version}
            onChange={v => setVersion(v)}
          />
        </Col>
        <Col>
          <RangePicker
            style={{ width: '220px' }}
            disabledDate={moment => moment && (moment.isBefore(datePickerMinAvailableMoment) || moment.isAfter(datePickerMaxAvailableMoment))}
            allowClear={false}
            allowEmpty={[false, false]}
            showToday={false}
            placeholder={[ze('日期范围', 'Time Range')]}
            value={[moment(minDate), moment(maxDate)]}
            onChange={(dates) => {
              const [minDate, maxDate] = (dates || [null, null]).map(x => x && x.format('YYYY-MM-DD'));
              setMinDate(minDate);
              setMaxDate(maxDate);
            }}
          />
        </Col>
        <Col>
          <Select
            style={{ width: '120px' }}
            options={SummaryVmOptions}
            value={vmStatus}
            onChange={v => setVmStatus(v)}
          />
        </Col>
        <Col>
          <CountrySelect
            style={{ width: '320px' }}
            value={countryList}
            isMultiple={true}
            onChangeWhenDropdownClosed={v => setCountryList(v)}
          />
        </Col>
        <Col>
          <Button
            type='primary'
            onClick={() => {
              fetchTrend();
            }}
          >{ t('common.查询') }</Button>
        </Col>
        <Col style={{ marginLeft: 'auto' }}>
          <Switch
            checkedChildren={ze('地图', 'Map')}
            unCheckedChildren={ze('列表', 'List')}
            checked={isDisplayMap}
            onClick={checked => setIsDisplayMap(checked)}
          />
        </Col>
      </Row>
      {isDisplayMap && <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', marginBottom: 20 }}>
        <CsTabs
          bigSize={true}
          activeKey={currentTab}
          onChange={tag => setCurrentTab(tag)}
          items={tabOptions}
        />
      </div>}
      <div className={_style.statByCountry}>
        {isDisplayMap && <div style={{ flex: 2.5 }}>
          <WorldMapChart countryStatList={countryStatList} currentTab={currentTab} tabsMap={tabsMap}/>
        </div>}
        {tableDom}
      </div>
    </div> }
  </Spin>;
};

StatByCountry.propTypes = {
  reduxState: PropTypes.object,
  actions: PropTypes.object,
};

const mapStateToProps = state => ({
  reduxState: state,
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({
    // ...productActions,
    // ...appActions,
    // ...globalActions,
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(StatByCountry);
