import { isNotNullish } from 'utils/nullish';
import moment from 'moment';
import { ze } from 'utils/zhEn';
import { ServerAppSettings } from 'utils/server-app-settings';

export function selectHasSpecifiedPermissionInCurrentApp(state, permission) {
  const isSuperAdmin = state.user.get('current').get('isSuper');
  if (isSuperAdmin) {
    return true;
  }

  const currentAppId = state.app.get('current').get('appId');
  if (!currentAppId) {
    return false;
  }
  const appIdToPermissionList = state.user.get('appIdToPermissionList');
  return (appIdToPermissionList[currentAppId] || []).includes(permission);
}

export function selectFrontendWebVolatileConfigValueByKey(state, key) {
  try {
    const frontendWebVolatileConfig = state.user.get('frontendWebVolatileConfig');
    const configList = frontendWebVolatileConfig.result;
    const configItem = configList.find(x => x.key === key);
    return isNotNullish(configItem) ? configItem.value : null;
  } catch (e) {
    console.error('selectFrontendWebVolatileConfigValueByKey error');
    return null;
  }
}

export function selectCurrentAppJoinedAppsInSameGroup(state) {
  const currentApp = state.app.get('current').toJS();
  const { joinedAppsInSameGroup, appGroupId } = currentApp;
  if (joinedAppsInSameGroup && joinedAppsInSameGroup.length > 0) {
    return joinedAppsInSameGroup.sort((a, b) => a.platformId - b.platformId);
  } else if (!appGroupId) {
    return [currentApp];
  } else {
    const appList = state.app.get('appList').toJS();
    return appList.filter(x => x.appGroupId === appGroupId).sort((a, b) => a.platformId - b.platformId);
  }
}

export function selectServerAppSettings(state, appId) {
  return state.app.get('appIdToServerAppSettings')[appId] || {};
}

export function selectNullableServerAppSettings(state, appId) {
  return state.app.get('appIdToServerAppSettings')[appId];
}

export function selectNullableCurrentAppSettings(state) {
  const appId = state.app.get('current').get('appId');
  return selectNullableServerAppSettings(state, appId);
}

export function selectCurrentAppCustomUserSceneTagLabel(state) {
  const appId = state.app.get('current').get('appId');
  const settings = selectServerAppSettings(state, appId);
  const customUserSceneTagLabelZh = settings[ServerAppSettings.keys.customUserSceneTagLabelZh];
  const customUserSceneTagLabelEn = settings[ServerAppSettings.keys.customUserSceneTagLabelEn];
  return ze(customUserSceneTagLabelZh, customUserSceneTagLabelEn);
}

export function selectFeatureTagOptionsInfo(state) {
  return state.seniorSearch.get('selectOptions').get('featureTag').toJS();
}

export function selectIssueCrashFilterCustomKeyOptionsInfo(state) {
  return state.seniorSearch.get('selectOptions').get('customKey').toJS();
}

export function selectIssueCrashFilterModelOptionsInfo(state) {
  return state.seniorSearch.get('selectOptions').get('model').toJS();
}

export function selectIssueCrashFilterOsVersionOptionsInfo(state) {
  return state.seniorSearch.get('selectOptions').get('osVersion').toJS();
}

export function selectCurrentAppDatePickerAvailableMomentRange(state) {
  const teamType = state.app.get('current').get('teamType');
  return teamType === 'COMMERCIAL'
    ? [moment().subtract(90, 'days').startOf('day'), moment().endOf('day')]
    : [moment().subtract(3, 'years').startOf('day'), moment().add(1, 'years').endOf('day')];
}
