import React, { useEffect } from 'react';
import { Select } from 'antd';
import { CsSelect } from 'components/antd-extension/CsSelect';
import { useDispatch, useSelector } from 'react-redux';
import { selectIssueCrashFilterModelOptionsInfo } from 'utils/selectors/selectors';
import { actionFetchModelOptions } from 'reducers/seniorSearch';

const MacDeviceSelect = ({
  appId,
  isReadOnly,
  value,
  onChange,
}) => {
  const { hasFetched, options } = useSelector(selectIssueCrashFilterModelOptionsInfo);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!appId) {
      return;
    }
    if (!hasFetched) {
      dispatch(actionFetchModelOptions());
    }
  }, [appId]);

  return <CsSelect
    style={{ width: '100%', fontSize: '14px' }}
    mode='multiple'
    options={options}
    optionFilterProp='label'
    value={value || []}
    onChange={(v) => {
      if (isReadOnly) {
        return;
      }
      onChange(v);
    }}
  />
};

export default MacDeviceSelect;
