import { createAction } from 'redux-actions';
import productRest from 'utils/productRest';
import globalRest from 'utils/globalRest';

export function getOptions(types) {
  return (dispatch, getState) => {
    const { appId, pid } = getState().app.get('current').toJS();
    dispatch({
      rest: globalRest.getSelector.get,
      data: {
        appId,
        pid,
        types,
      },
    });
  };
}

// 成员角色变更
export function updateAppMember(type, localUserId, isOperator, isAppGroup) {
  return (dispatch, getState) => {
    const { appId, pid, appGroupId } = getState().app.get('current').toJS();
    return dispatch({
      rest: productRest.member.update,
      data: {
        appId,
        pid,
        appGroupId: isAppGroup ? appGroupId : undefined,
        localUserId,
        type,
        isOperator,
      },
    });
  };
}

// 成员删除
export function deleteAppMember(appId, pid, appGroupId, localUserId, deleteBothAppAndGroup) {
  return (dispatch, getState) => {
    return dispatch({
      rest: productRest.member.delete,
      data: {
        appId,
        pid,
        appGroupId,
        deleteBothAppAndGroup,
        localUserId,
      },
    });
  };
}

// 增加成员
export function addAppMembers(userIds) {
  return (dispatch, getState) => {
    const { appId, pid } = getState().app.get('current').toJS();
    return dispatch({
      rest: productRest.member.add,
      data: {
        appId,
        pid,
        userIds,
      },
    });
  };
}

// 日报读取
export function getDailyConfig() {
  return (dispatch, getState) => {
    const { appId, pid } = getState().app.get('current').toJS();
    dispatch({
      rest: productRest.dailyConfig.get,
      data: {
        appId,
        pid,
      },
    });
  };
}

// 更新日报配置
export function updateDailyConfig(reportConfig) {
  return (dispatch, getState) => {
    const currentApp = getState().app.get('current');
    return dispatch({
      rest: productRest.dailyConfig.update,
      data: Object.assign({}, reportConfig, { appId: currentApp.get('appId'), platformId: currentApp.get('pid') }),
    });
  };
}

const generateAction = (rest) => {
  return () => {
    return (dispatch, getState) => {
      const { appId, pid } = getState().app.get('current').toJS();
      dispatch({
        rest,
        data: {
          appId,
          platformId: pid,
        },
      });
    };
  };
};

export function getMemberList() {
  return (dispatch, getState) => {
    const { appId, pid } = getState().app.get('current').toJS();
    return dispatch({
      rest: globalRest.getMemberList.get,
      data: {
        appId,
        platformId: pid,
      },
    });
  };
}

export function getNewMemberList(params) {
  return (dispatch, getState) => {
    const { appId, pid } = getState().app.get('current').toJS();
    return dispatch({
      rest: globalRest.getNewSelector.get,
      data: {
        appId: params && params.appId || appId,
        pid: params && params.pid || pid,
        types: 'member',
      },
    });
  };
}

export function getAppMemberList(params) {
  return (dispatch, getState) => {
    const { appId, pid } = getState().app.get('current').toJS();
    return dispatch({
      rest: globalRest.getNewSelector.get,
      data: {
        appId: params && params.appId || appId,
        pid: params && params.pid || pid,
        types: 'member',
      },
    });
  };
}

export function getVersionList() {
  return (dispatch, getState) => {
    const { appId, pid } = getState().app.get('current').toJS();
    return dispatch({
      rest: productRest.versions.get,
      data: {
        appId,
        pid,
        needAll: true,
      },
    });
  };
}

export function disableVersion(version, isShow) {
  return (dispatch, getState) => {
    const { appId, pid } = getState().app.get('current').toJS();
    const { userId } = getState().user.get('current').toJS();
    return dispatch({
      rest: productRest.disableVersion.get,
      data: {
        appId,
        pid,
        version,
        isShow,
        userId,
      },
    });
  };
}

export function enableVersion(version, isShow) {
  return (dispatch, getState) => {
    const { appId, pid } = getState().app.get('current').toJS();
    const { userId } = getState().user.get('current').toJS();
    return dispatch({
      rest: productRest.enableVersion.get,
      data: {
        appId,
        pid,
        version,
        isShow,
        userId,
      },
    });
  };
}

export function deleteProduct() {
  return (dispatch, getState) => {
    const { appId, pid } = getState().app.get('current').toJS();
    const { userId } = getState().user.get('current').toJS();
    return dispatch({
      rest: productRest.product.delete,
      data: {
        appId,
        pid,
        userId,
      },
    });
  };
}

/**
 * 更新appinfo
 */

export function updateAppInfo({
  appId, name, desc, type, logoUrl, userId, pid,
  utcOffset,
}) {
  return (dispatch) => {
    return dispatch({
      rest: productRest.appInfo.update,
      data: {
        appId,
        name,
        description: desc,
        type,
        logoUrl,
        userId,
        platformId: pid,
        utcOffset,
      },
    });
  };
}

// export const getMemberList = generateAction(globalRest.getMemberList.get);

export const updateVersionParams = createAction('UPDATE_VERSION_PARAMS');

export const changeCurTab = createAction('CHANGE_CURTAB');

export const isShowInvitatePop = createAction('SHOW_INVITATEPOP');

export const appInfo = generateAction(productRest.appInfo.get);

export const changeChecked = createAction('CHANGE_CHECKED');

export const changeAppLogo = createAction('CHANGE_APPLOGO');

/**
 * 创建一个产品
 */
export function initApp(data) {
  return (dispatch) => {
    // console.log('data in initApp action', data)
    return dispatch({
      rest: productRest.product.init,
      data,
    });
  };
}

/**
 * 创建OA产品
 */
export function initOaApp(data) {
  return (dispatch) => {
    return dispatch({
      rest: productRest.product.initOa,
      data,
    });
  };
}

/**
 * 检测sdk是否集成成功
 */
export function sdkHasReport() {
  return (dispatch, getState) => {
    const { appId, pid } = getState().app.get('current').toJS();
    return dispatch({
      rest: productRest.product.hasReport,
      data: {
        appId,
        pid,
      },
    });
  };
}


export const checkVersion = createAction('CHECK_VERSION');

export const clearChecked = createAction('CLEAR_CHECKED');

/**
 * 读取webhook配置
 */
export function getWebhookConfig() {
  return (dispatch, getState) => {
    const { appId, pid } = getState().app.get('current').toJS();
    return dispatch({
      rest: productRest.webhookConfig.get,
      data: {
        appId,
        pid,
      },
    });
  };
}

/**
 * 更新webhook配置
 */
export function alterWebhookConfig(thirdpartyName, callbackUrl, hookName, hookIds, lastUuids) {
  return (dispatch, getState) => {
    const { appId, pid } = getState().app.get('current').toJS();
    return dispatch({
      rest: productRest.webhookConfig.alter,
      data: {
        appId,
        pid,
        thirdpartyName,
        callbackUrl,
        hookName,
        hookIds,
        lastUuids,
      },
    });
  };
}

/**
 * 删除webhook配置
 */
export function deleteWebhookConfig(thirdpartyName, callbackUrl, hookName, hookIds, lastUuids) {
  return (dispatch, getState) => {
    const { appId, pid } = getState().app.get('current').toJS();
    return dispatch({
      rest: productRest.webhookConfig.delete,
      data: {
        appId,
        pid,
        thirdpartyName,
        callbackUrl,
        hookName,
        hookIds,
        lastUuids,
      },
    });
  };
}

