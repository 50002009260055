import i18n from 'i18n.js';
import { isNotNullish } from 'utils/nullish';

export const VmTypeInt = Object.freeze({
  ALL: 0,
  REAL_DEVICE: 1,
  EMULATOR: 2,
  CLOUD_GAMING: 3,
  PLAYCOVER: 4,
});

const androidVmTypeList = [
  VmTypeInt.REAL_DEVICE,
  VmTypeInt.EMULATOR,
  VmTypeInt.CLOUD_GAMING,
];

const allOptions = Object.values(VmTypeInt)
  .map(x => ({
    label: i18n.t(`vmTypeOptions.${x}`),
    value: x,
  }));

const androidOptions = allOptions.filter(x => androidVmTypeList.includes(x.value));

export const VmTypeUtil = Object.freeze({
  getAndroidOptions() {
    return androidOptions;
  },

  toI18n(vmTypeInt) {
    const tKey = `vmTypeOptions.${vmTypeInt}`;
    return i18n.exists(tKey) ? i18n.t(tKey) : vmTypeInt;
  },
});
