import { isNullish } from 'utils/nullish';
import { CsvUtil } from 'utils/csv-util';

export const VersionUtil = Object.freeze({
  WILDCARD_CHARACTER: '*',

  /**
   * 给定的版本列表里是否跨多版本。
   * 全版本不算多版本，但是带通配符*的版本算多版本。
   * @param versionList {string[]}
   * */
  isMultipleVersions(versionList) {
    if (isNullish(versionList)) {
      return false;
    }
    const v = Array.isArray(versionList) ? versionList : [versionList];
    return v.length >= 2 || String(v[0]).includes('*');
  },

  /** 将逗号分隔的版本字符串转成版本数组 */
  makeVersionListFromVersionCsvString(versionCsv) {
    if (isNullish(versionCsv)) {
      return [];
    }
    try {
      return CsvUtil.singleLineParse(versionCsv);
    } catch (e) {
      console.error(`makeVersionListFromVersionCsvQueryString parse failed, v = ${versionCsv}, e = `, e);
      return [versionCsv];
    }
  },

  /**
   * 比较两个版本的节的大小关系。 返回负数表示a<b，正数表示a>b，0表示a=b
   * 排序依据是：如果都是数值，按数值排序。如果都是字符串，按字符串排序。数值排在字符串后面，这样版本倒序列表数值的排在前面
   * @param a {string}
   * @param b {string}
   */
  compareVersionSegment(a, b) {
    const [av, bv] = [Number(a), Number(b)];
    if (Number.isNaN(av) || Number.isNaN(bv)) {
      if (!Number.isNaN(av)) {
        return 1;
      }
      if (!Number.isNaN(bv)) {
        return -1;
      }
      return (a || '').localeCompare(b || '');
    }
    return Math.sign(av - bv);
  },

  /**
   * 比较两个版本号的大小关系。返回负数表示a小于b，正数表示a大于b，0表示a=b
   * 排序依据是：按照点号将版本号分割成节，然后按照compareVersionSegment的节大小逻辑比较各节的大小。
   * @param a {String}
   * @param b {String}
   */
  compareVersion(a, b) {
    const aSegList = (a || '').trim().split('.');
    const bSegList = (b || '').trim().split('.');
    const commonLength = Math.min(aSegList.length, bSegList.length);
    for (let i = 0; i < commonLength; i++) {
      const segCompareResult = this.compareVersionSegment(aSegList[i], bSegList[i]);
      if (segCompareResult !== 0) {
        return segCompareResult;
      }
    }
    return Math.sign(aSegList.length - bSegList.length);
  },

  /**
   * 比较两个CrashSight SDK的版本号。
   * ！！注意只能用于CS SDK自己的版本号对比，不能用于异常上报里面项目自己的版本号相关对比。
   * 和compareVersion不同的是，CS SDK的版本号需要兼容GCloud等版本的SDK额外附带的前后缀
   * */
  compareCsSdkVersion(a, b) {
    function norm(version) {
      const match = (version || '').match(/\d+(\.\d+)*/);
      return match ? match[0] : '';
    }
    return this.compareVersion(norm(a), norm(b));
  },
});
