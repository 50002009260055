import React, { useState, useEffect } from 'react';
import { Button, Drawer, Menu, Checkbox, Tag, Badge, Empty, Spin, Image } from 'antd';
import { ClearOutlined } from '@ant-design/icons';
import ReactMarkdown from 'react-markdown';
import gfm from "remark-gfm";
import { isZh, ze } from 'utils/zhEn';

import { NoticeRecordCategory } from 'utils/constants/notice-record-category';
import reportEvent, { EVENT_ACTIONS } from 'utils/reportEvent';
import scss from './NoticeDrawer.scss';
import { ErrorBoundary } from 'react-error-boundary';
import NoJumpNextPagination from 'components/antd-extension/NoJumpNextPagination.jsx';
import NoticeDrawerEcharts from './NoticeDrawerEcharts';
import IconCrashList from 'svg/v2/newcs_dashboard_left_crash_icon_normal.svg';
// import crash_icon from 'svg/v2/newcs_dashboard_left_error_icon_normal.svg';
import IconErrorList from 'svg/v2/newcs_dashboard_left_error_icon_normal.svg';
import MarkasreadNormal from 'svg/v2/newcs_dashboard_message_markasread_normal_icon.svg';
import MarkasunreadNormal from 'svg/v2/newcs_dashboard_message_markasunread_normal_icon.svg';
import { CS_STYLES } from 'utils/constants/style-constants';

const App = (prop) => {
  const { drawerOpen, drawerDisable, listNotices, getMarkAllAsRead, getChangeReadStatus } = prop;

  const items = [
    {
      label:`${ze('全部','All')}`,
      key:'ADD',
    },{
      label:`${ze('系统告警','System Alert')}`,
      key: 'System',
    },{
      label:`${ze('自定义告警','Custom Alert')}`,
      key: 'Custom',
    },{
      label:`${ze('公告','Notice')}`,
      key:'BULLETIN',
    }
  ];

  const announcementTypeList = [
    {
      label:`${ze('功能上线','Feature Launch')}`,
      value:NoticeRecordCategory.NEW_FEATURE,
      color: "default",
      icon: null,
    },{
      label:`${ze('功能优化','Feature Optimization')}`,
      value:NoticeRecordCategory.OPTIMIZATION,
      color: "default",
      icon: null,
    },{
      label:`${ze('版本迭代','Version Update')}`,
      value:NoticeRecordCategory.NEW_ITERATION,
      color: "default",
      icon: null,
    },{
      label:`${ze('自定义告警 - 崩溃','Custom Crash')}`,
      value:NoticeRecordCategory.APP_CUSTOM_CRASH_ALARM,
      color:'red',
      icon: <IconCrashList width="14" height="14" viewBox="0 0 20 20" style={{color:'#FF5461'}} />,
    },{
      label:`${ze('自定义告警 - 错误','Custom Error')}`,
      value:NoticeRecordCategory.APP_CUSTOM_ERROR_ALARM,
      color:'purple',
      icon: <IconErrorList  width="14" height="14" viewBox="0 0 20 20" style={{color:'#a452DD'}}/>,
    },{
      label:`${ze('系统告警 - 崩溃','System Alert and Crash')}`,
      value:NoticeRecordCategory.APP_PRECONFIGURED_CRASH_ALARM,
      color:'#FF5461',
      icon: <IconCrashList width="14" height="14" viewBox="0 0 20 20" style={{color:'#FFFFFF'}} />,
    },{
      label:`${ze('系统告警 - 错误','System Alert and Error')}`,
      value:NoticeRecordCategory.APP_PRECONFIGURED_ERROR_ALARM,
      color:'#A452DD',
      icon: <IconErrorList  width="14" height="14" viewBox="0 0 20 20" style={{color:'#FFFFFF'}}/>,
    },
  ];

  const [ readerData, setReaderData ] = useState([]);
  const [ presentState, setPresentState ] = useState(false);
  const [ contextState, setContextState ] = useState('ADD');
  const [ loading, setLoading ] = useState(false);
  const [ prevListNotices, setPrevListNotices ] = useState(listNotices);
  const [ current, setCurrent ] = useState(1);
  const [ pageSize, setPageSize ] = useState(10);
  const [ perPage, setPerPage ] = useState([]);
  // console.log('111111111111111111111111perPage',perPage)
  // 定义的prevListNotices是因为在每个tab下进行操作的时候listNotices会发生变化，但是useEffect不能监听到深层的变化，定义变量和当前进来的listNotices进行相等判断。
  useEffect(() => {
    if (listNotices !== prevListNotices) {
      updateData(contextState,listNotices);
    }
    setPrevListNotices(listNotices);
  }, [listNotices, prevListNotices]);
  useEffect(() => {
    const indexOfLastItem = current * pageSize;
    const indexOfFirstItem = indexOfLastItem - pageSize;
    const currentItems = readerData.slice(indexOfFirstItem, indexOfLastItem);
    setPerPage(currentItems);
  },[current,readerData])

  useEffect(() => {
    setCurrent(1);
  },[pageSize])

  const updateData = (key,value) => {
    if(key == 'ADD'){
      setReaderData(value);
    } else if (key == 'System') {
      const data = value.filter(item => (
        item.category === NoticeRecordCategory.APP_PRECONFIGURED_CRASH_ALARM ||
        item.category === NoticeRecordCategory.APP_PRECONFIGURED_ERROR_ALARM
      ));
      setReaderData(data);
    } else if (key == 'Custom') {
      const data = value.filter(item => (
        item.category === NoticeRecordCategory.APP_CUSTOM_CRASH_ALARM ||
        item.category === NoticeRecordCategory.APP_CUSTOM_ERROR_ALARM
      ));
      setReaderData(data);
    } else {
      const data = value.filter(item => (
        item.category === NoticeRecordCategory.NEW_FEATURE ||
        item.category === NoticeRecordCategory.OPTIMIZATION ||
        item.category === NoticeRecordCategory.NEW_ITERATION
      ));
      setReaderData(data);
    }
  }

  const getUnreadState = (e) => {
    setPresentState(!presentState);
    if(e.target.checked){
      const data = listNotices.filter(item => item.hasRead === false);
      updateData(contextState,data);

      reportEvent({
        action: EVENT_ACTIONS.CLICK,
        tp1: '只看未读',
      });
    } else {
      updateData(contextState,listNotices);
    }
    setCurrent(1);
  }

  const updateContext = (key) => {
    setPresentState(false);
    setContextState(key);
    updateData(key,listNotices);
  }

  //分页器切换
  const paginationChange = (page,pageSize) => {
    setCurrent(page);
  }

  const paginationSizeChange = (current,size) => {
    setPageSize(size);
  }

  const updateMouseEnter = (recordId) => {
    setReaderData((prevDataList) =>
      prevDataList.map((item) =>
        item.recordId === recordId ? { ...item, showEdit: true } : item
      )
    );
  }

  const updateMouseLeave = (recordId) => {
    setReaderData((prevDataList) =>
      prevDataList.map((item) =>
        item.recordId === recordId ? { ...item, showEdit: false } : item
      )
    );
  }

  const updateReadState = (recordId,hasRead) => {
    setLoading(true);
    setPresentState(false);
    getChangeReadStatus(recordId,hasRead);
    setLoading(false);
  }

  const updateAllAsRead = () => {
    setLoading(true);
    setPresentState(false);
    getMarkAllAsRead();
    setLoading(false);

    reportEvent({
      action: EVENT_ACTIONS.CLICK,
      tp1: '一键已读',
    });
    setCurrent(1);
  }

  const getLabelByValue = (value) => {
    const selectedType = announcementTypeList.find(type => type.value === value);
    return (
      <div>
        {selectedType && (
          <Tag color={selectedType.color}>
            <div style={{display:'flex',gap:'5px',alignItems:'center'}}>
              <div style={{height:'16px'}}>{selectedType.icon}</div>
              <div>{selectedType.label}</div>
            </div>
          </Tag>
        )}
      </div>
    )
  }

  const noticeHeadMenu = items.map(item => (
    <li
      key={item.key}
      style={{
        borderBottom: contextState === item.key ? `4px solid ${CS_STYLES.PRIMARY_COLOR}` : '',
      }}
      onClick={() => updateContext(item.key)}
    >
      <div
        className={contextState === item.key ? scss.navigatorChoose : scss.navigatorUnselected}
      >
        {item.label}
      </div>
    </li>
  ))

  return (
    <div>
      <Drawer
        placement='right'
        closable={false}
        onClose={drawerDisable}
        open={drawerOpen}
        width={480}
        bodyStyle={{
          padding: 0,
        }}
      >
        <Spin spinning={loading}>
          <div className={scss.notice}>
            <div>
              <ul class={scss.navigator}>{noticeHeadMenu}</ul>
              <div
                style={{
                  display:'flex',
                  borderTop: '1px solid #E9E9E9',
                  borderBottom: '1px solid #E9E9E9',
                  fontSize: '14px',
                  padding:'16px 24px'}}>
                <Checkbox checked ={presentState} onChange={(e) => getUnreadState(e)}>{ze('只看未读','View only Unread')}</Checkbox>
                <div
                  onClick={updateAllAsRead}
                  style={{cursor: 'pointer',marginLeft:'20px',display:'flex',alignItems:'center',gap:'4px'}}
                  className={scss.MarkasreadNormal}
                >
                  {/* <img src={markasread_normal_icon} alt="" /> */}
                  <MarkasreadNormal />
                  <div>{ze('一键已读','Mark all as Read')}</div>
                </div>
              </div>
            </div>
            <div style={{height:'calc(100vh - 85px - 44px)',overflowX:"auto"}}>
              {perPage.length > 0 ? perPage.map((item,index) => (
                <div key={item.recordId}>
                  <hr/>
                  <div style={{ backgroundColor: item.showEdit ? '#f0f0f0' : 'transparent'}}
                    onMouseEnter={() => updateMouseEnter(item.recordId)}
                    onMouseLeave={() => updateMouseLeave(item.recordId)}
                  >
                    <div style={{display:'flex',alignItems:'center',height:'22px',padding:'16px 24px 0 0'}}>
                      <div style={{width:'24px'}}>{ !item.hasRead && <Badge status="error" style={{margin: '8px'}} size='small'/>}</div>
                      <div>{getLabelByValue(item.category)}</div>
                      <div style={{flex:'1',fontWeight:'bolder',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis'}}>{isZh() ? item.titleZh : item.titleEn}</div>
                      {item.showEdit && (
                        <Button type="link" onClick={() => updateReadState(item.recordId, item.hasRead)}>
                          {item.hasRead ? <div class={scss.Read}>{<MarkasreadNormal />}</div> : <div className={scss.unread}><MarkasunreadNormal /></div>}
                        </Button>
                      )}
                    </div>
                    <div style={{margin:'16px 24px'}}>
                      <ReactMarkdown
                        remarkPlugins={[gfm]}
                        linkTarget='_blank'
                        children={isZh() ? item.contentZh : item.contentEn}
                          components={{
                            img({ node, ...props}) {
                              return <div style={{ maxWidth:'100%' }}>
                                <Image src={props.src} />
                              </div>
                            },
                            a({ node, ...props }) {
                              const handleClick = (event) => {
                                updateReadState(item.recordId, item.hasRead)
                              };
                              return <a onClick={handleClick} {...props} />;
                            },
                          }}
                        />
                    </div>
                    <ErrorBoundary fallback={<div style={{ color: 'red',fontSize:'14px' }}>
                      {ze('渲染出错，请联系管理员','Render error, please contact administrator.')}
                    </div>}>
                      <NoticeDrawerEcharts key={item.recordId} data={item.otherInfo}/>
                    </ErrorBoundary>
                    <div style={{padding:'0 0 20px 24px',color:'#AAA',fontSize:'13px'}}>{item.updateTime}</div>
                  </div>
                </div>

              )) :  <div style={{position: 'relative',width:'100%',height:'100%'}}>
                <Empty
                  description={ze('暂无数据','No messages available')}
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  style={{position:'absolute',top:'50%',left:'50%',transform: 'translate(-50%, -50%)'}}/>
              </div>}
              <div style={{display:'flex',justifyContent:'flex-end',margin:'10px 0'}}>
                <NoJumpNextPagination
                  size='small'
                  total={readerData.length}
                  current={current}
                  pageSize={pageSize}
                  onChange={(page,pageSize) => paginationChange(page,pageSize)}
                  onShowSizeChange={ (current,size) => paginationSizeChange(current,size)}
                />
              </div>
            </div>
          </div>
        </Spin>
      </Drawer>
    </div>
  );
};
export default App;
