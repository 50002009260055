import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { transformDateFormat } from 'utils/StringUtils';
import { Link } from 'react-router-dom';
import { TagList } from 'components/commons';
import moment from 'moment';
import EmptyData from 'components/commons/EmptyData';
import {
  formatNum,
  getIssueDetailUrlPrefix,
  convertIssueAssigneeListToDisplayText
} from 'utils/helper';
import { Translation, useTranslation } from 'react-i18next';
import { Button, Col, DatePicker, Select, Space, Spin, Tooltip, Table, Row, Radio } from 'antd';
import { TopIssueSortField, TopIssueUtils } from 'components/exception/ProductSummaryPage/TopIssueList/TopIssueList';
import CardHead from 'components/commons/CardHead';
import { connect, useSelector } from 'react-redux';
import XLSX from 'xlsx';
import { CountryUtil } from 'utils/country';
import CountrySelect from 'components/exception/ProductSummaryPage/CountrySelect';
import SortButton from 'components/exception/ProductSummaryPage/SortButton';
import CsClampDiv from 'components/commons/CsClampDiv/CsClampDiv';
import { useImmer } from 'use-immer';
import VersionHybridSelect from 'components/commons/VersionHybridSelect';
import { CsvUtil } from 'utils/csv-util';
import { VersionUtil } from 'utils/version-util';
import { orange } from '@ant-design/colors';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { DemoUtil } from 'utils/demo-util';
import { TimeUtil } from 'utils/time-util';
import { ze, isZh } from 'utils/zhEn';
import { formatEnNumber } from 'utils/format/format-number';
import { selectCurrentAppDatePickerAvailableMomentRange } from 'utils/selectors/selectors';
import { DividerWithTime } from './DividerWithTime';
import topIssueListScss from './TopIssueList/style.scss';
import IssueTapdBugStatusEx from 'components/exception/BaseProblemPage/IssueItem/IssueTapdBugStatusEx';
import reportEvent, { EVENT_ACTIONS, ReportEventUtil } from 'utils/reportEvent';
import HourlyTopIssiueIcon from 'svg/v2/newcs_dashboard_overview_hourlytopissues_icon.svg';
import { UpdateTimeTag } from './UpdateTimeTag';
import CsDownloadButton from 'components/commons/CsDownloadButton/CsDownloadButton';
import IssueIdIcon from 'svg/v2/newcs_dashboard_overview_dailytopissue_list_issueid_icon.svg';
import IssueNameIcon from 'svg/v2/newcs_dashboard_overview_dailytopissue_list_yichangname_icon.svg';
import StatusItem from 'components/commons/StatusItem/StatusItem';
import CsCollapseButton from 'components/commons/CsCollapseButton/CsCollapseButton';
import DateSelectIcon from 'svg/v2/newcs_dashboard_dateselect_icon.svg';
import WrappedTipsIcon from 'components/antd-extension/WrappedTipsIcon.jsx';
import { CS_STYLES } from 'utils/constants/style-constants';


const ALL_VERSION = '-1';

const FIELDS = Object.freeze({
  DEVICES: 'DEVICES',
  NUM: 'NUM',
  FLUCTUATE_PREV_DAY: 'FLUCTUATE_PREV_DAY',
  FLUCTUATE_PREV_HOUR: 'FLUCTUATE_PREV_HOUR',
  FIRST_TIME: 'FIRST_TIME',
});

function formatRate(numerator, denominator) {
  if (parseInt(denominator) === 0) {
    return '0 %';
  }
  return TopIssueUtils.formatPercentage(100 * numerator / denominator);
}

/**
 * collapseIssues,onToggleCollapseIssues 暂时用不到了，使用 isExpanded
 */
const RealTimeTopIssue = ({
  demoApp,
  collapseIssues,
  realTimeTopIssueData,
  handleChangeStatus,
  onToggleCollapseIssues,
  onClickUpsertTapdBugByIssueIdList,
  onBugUnbind,
  style,
  pageType,
  members,
  actions,
  reduxState,
  isRegionalDashboard,
}) => {
  const { t, i18n } = useTranslation();

  const currentApp = useMemo(() => reduxState.app.get('current').toJS(), [reduxState.app.get('current')]);
  const { appId } = currentApp;
  const bugTrackingSimplifiedInfo = useMemo(() => reduxState.app.get('appIdToBugTrackingSimplifiedInfo')?.[appId],
    [reduxState.app, appId]);
  const { tapdWorkspaceId, jiraProjectId } = bugTrackingSimplifiedInfo || {};
  const showBugStatusField = !!tapdWorkspaceId || !!jiraProjectId;

  const [
    datePickerMinAvailableMoment,
    datePickerMaxAvailableMoment
  ] = useSelector(selectCurrentAppDatePickerAvailableMomentRange);

  const [isExpanded, setIsExpanded] = useState(false);
  const [sortField, setSortField] = useState(null);
  const [sortOrder, setSortOrder] = useState(null);
  const [itemIndexToIsHovering, updateItemIndexToIsHovering] = useImmer({});

  function getOrder(field) {
    return sortField === field ? sortOrder : null;
  }

  function onChangeSort(sortField, sortOrder) {
    setSortOrder(sortOrder);
    setSortField(sortOrder ? sortField : null);
  }

  function renderIssue(actions, demoApp, curIssueData, handleChangeStatus, style, pageType, members, realTimeTopIssueHour, realTimeTopIssueData, sortField, sortOrder) {
    let issues = [];
    const { accessDevices, crashDevices, prevHourAccessDevices, prevDaySameHourAccessDevices } = realTimeTopIssueData;

    if (curIssueData) {
      const issueList = curIssueData
        .map(x => ({
          ...x,
          firstUploadTime: moment(TimeUtil.fixChaosMillis(x.firstUploadTimestamp)).format('YYYY-MM-DD HH:mm:ss'),
          prevDayFluctuate: TopIssueUtils.calcFluctuatePercentage(x.crashUser, accessDevices, x.preDayCrashUser, prevDaySameHourAccessDevices),
          prevHourFluctuate: TopIssueUtils.calcFluctuatePercentage(x.crashUser, accessDevices, x.prevHourCrashDevices, prevHourAccessDevices),
          firstUploadTimestamp: x.firstUploadTimestamp,
        }));

      if (sortOrder && sortField) {
        issueList.sort((a, b) => {
          let ascRes = 0;
          if (sortField === FIELDS.FLUCTUATE_PREV_DAY) {
            ascRes = a.prevDayFluctuate - b.prevHourFluctuate;
          } else if (sortField === FIELDS.FLUCTUATE_PREV_HOUR) {
            ascRes = a.prevHourFluctuate - b.prevHourFluctuate;
          } else if (sortField === FIELDS.FIRST_TIME) {
            ascRes = a.firstUploadTimestamp - b.firstUploadTimestamp;
          } else if (sortField === FIELDS.NUM) {
            ascRes = a.crashNum - b.crashNum;
          } else if (sortField === FIELDS.DEVICES) {
            ascRes = a.crashUser - b.crashUser;
          }
          return sortOrder === 'ascend' ? ascRes : -ascRes;
        });
      }

      issues = issueList.map((issue, i) => {
        const { assigneeList } = issue.issueInfo || {};
        const isState1 = parseInt(issue.state) === 1;

        const assigneeListDisplayText = convertIssueAssigneeListToDisplayText(assigneeList);
        const { prevDayFluctuate, prevHourFluctuate } = issue;
        let prevDayArrowClassName = '';
        if (prevDayFluctuate > 0) {
          prevDayArrowClassName = 'increase';
        } else if (prevDayFluctuate < 0) {
          prevDayArrowClassName = 'decrease';
        }
        let prevHourArrowClassName = '';
        if (prevHourFluctuate > 0) {
          prevHourArrowClassName = 'increase';
        } else if (prevHourFluctuate < 0) {
          prevHourArrowClassName = 'decrease';
        }
        const { addTag, delTag } = actions;

        const issueDetailUrlPrefix = getIssueDetailUrlPrefix(pageType);

        issue.addTag = addTag;
        issue.delTag = delTag;
        issue.i = i;
        issue.style = style;
        issue.crashDevices  = crashDevices;
        issue.accessDevices = accessDevices;
        issue.assigneeListDisplayText = assigneeListDisplayText;
        issue.assigneeList = assigneeList;
        issue.isState1 = isState1;
        issue.issueDetailUrlPrefix = issueDetailUrlPrefix;
        issue.prevDayFluctuate = prevDayFluctuate;
        issue.prevDayArrowClassName = prevDayArrowClassName;
        issue.prevHourArrowClassName = prevHourArrowClassName;
        issue.prevHourFluctuate = prevHourFluctuate;
        issue.statusNo = parseInt(issue.state)
        return {
          issue: issue,
          problemAbnormal: issue,
          influenceEquipment: issue,
          comparisonYesterday: issue,
          comparisonPriorHour: issue,
          appearTime: issue,
          happenFrequency: issue,
          disposeState: issue,
        }
      });
    }
    return issues;
  }

  const selectOptions = reduxState.summary.get('selectOptions').toJS();
  const searchParams = reduxState.summary.get('searchParams').toJS();
  const { rankVersion } = selectOptions;
  const {
    realTimeTopIssueSortField,
    realTimeTopIssueVersion,
    realTimeTopIssueCountry,
    realTimeTopIssueLoading,
    realTimeTopIssueHour,
    realTimeTopIssueLimit,
  } = searchParams;

  const bigDataRealtimeUpdateMillis = reduxState.summary.get('bigDataRealtimeUpdateMillis');

  const versionListValue = realTimeTopIssueVersion !== ALL_VERSION
    ? CsvUtil.singleLineParse(realTimeTopIssueVersion)
    : [];
  const versionTree = rankVersion.versionTree;
  const isMultipleVersions = VersionUtil.isMultipleVersions(versionListValue);

  const [countryListValue, setCountryListValue] = useState([]);
  useEffect(() => {
    setCountryListValue(CountryUtil.queryStringToList(realTimeTopIssueCountry));
  }, [realTimeTopIssueCountry]);

  if (!realTimeTopIssueData) {
    return (<div></div>);
  }
  const curIssueData = realTimeTopIssueData['topIssueList'];

  useEffect(() => {
    if (curIssueData?.length && !isExpanded) {
      setIsExpanded(true);
    }
  }, [curIssueData])

  // 将01,02这种时间改成区间，01:00-02:00,02:00-03:00这样
  const formatDateTimeRange = (hour) => {
    const startHour = hour.padStart(2, '0');
    let endHour = '';

    if (hour === '23') {
      endHour = '23:59';
    } else {
      const nextHour = (parseInt(hour) + 1).toString().padStart(2, '0');
      endHour = `${nextHour}:00`;
    }
    return `${startHour}:00-${endHour}`;
  }

  const hour = realTimeTopIssueHour.slice(8);
  let displayStr = `${transformDateFormat(realTimeTopIssueHour.slice(0, 8))} ${formatDateTimeRange(hour)}`;

  const sortFieldOptions = [
    { label: ze('按设备数', 'By Affected Devices'), value: 'DEVICE_COUNT' },
    { label: ze('按次数', 'By Reports'), value: 'REPORT_COUNT' },
  ];

  const topIssueCountOptions = [5, 10, 20, 50]
    .map(x => ({ value: x, label: t(`DATATYPESELECT.topN`, { n: x }) }));

  const headArea = <CardHead
      title={ t("DATATYPESELECT.hourlyTopIssue") } hideDivider={!isExpanded} svgIcon={<HourlyTopIssiueIcon />}
      onClick={() => {
        const newIsExpand = !isExpanded;
        setIsExpanded(newIsExpand);
        ReportEventUtil.reportCardHeadClickEvent('每小时TOP问题', false, newIsExpand);
      }}
      >
      <div style={{ display: 'flex',flexGrow: '1', alignItems: 'center', justifyContent: 'flex-end'}}>
        <UpdateTimeTag updateTime={bigDataRealtimeUpdateMillis} type="fiveMinute" />
        <CsDownloadButton onClick={(e) => downloading(e)} style={{marginLeft: '24px', marginRight: '24px'}} />
        {/* {
          collapseIssues ? <UpOutlined onClick={() => onToggleCollapseIssues()} /> : <DownOutlined onClick={() => onToggleCollapseIssues()} />
        } */}
        <CsCollapseButton
          extraStyle={{ width: '14px', height: '14px' }}
          isCollapsed={!isExpanded}
          enableHover={true}
          onClick={(e) => {
            e.stopPropagation();
            const newIsExpand = !isExpanded;
            setIsExpanded(newIsExpand);
            ReportEventUtil.reportCardHeadClickEvent('每小时TOP问题', true, newIsExpand);
          }}
        />
      </div>
      {/* <div style={{ flexGrow: '1', display: 'flex', flexDirection: 'column' }}>
      <div style={{ display: 'flex', gap: '8px', flexWrap: 'wrap', alignItems: 'center' }}> */}
      {/* <div style={{paddingLeft:'20px'}}>
        <Button  icon={<DownloadOutlined />} onClick={() => downloading()}>
          {ze('下载数据', 'Download')}
        </Button>
      </div> */}
      {/* <div style={{ flexGrow: 1 }}/> */}

      {/* <Button type='link' onClick={() => onToggleCollapseIssues()}>
        {collapseIssues ? t("EXCP_OVERVIEW.collapse") : t("EXCP_OVERVIEW.expand")}
      </Button> */}
    </CardHead>;

  const empty = {
    emptyText:<EmptyData text={ t("REALTIMETOPISSUE.noData") } />
  }

  function createExcel(data, title){
    const { utils } = XLSX;
    var ws_name = "sheet1";
    const wb= utils.book_new();
    /* make worksheet */
    var ws = utils.aoa_to_sheet(data);
    /* Add the worksheet to the workbook */
    XLSX.utils.book_append_sheet(wb, ws, ws_name);
    XLSX.writeFile(wb, title);
  }

  //这个地方和TopIssueList 里面的formatPercentage 逻辑重复
  function formatPercentage(percentage) {
    if (percentage === Number.POSITIVE_INFINITY) {
      return '∞';
    }
    if (!Number.isFinite(percentage)) {
      return `${percentage}`;
    }
    if (Math.abs(percentage) >= 0.1 || percentage === 0) {
      return `${percentage.toFixed(2)} %`;
    }
    return `${percentage.toFixed(4)} %`;
  }

  const downloading = (e) => {
    e.stopPropagation();
    const topIssueEnrichedIssueList = renderIssue(actions, demoApp, curIssueData, handleChangeStatus, style, pageType, members, realTimeTopIssueHour, realTimeTopIssueData, sortField, sortOrder)
    const header = [
      t('issueCrashFilterKey.issueId'),
      ze('平台链接', 'Website URL'),
      t("issueCrashFilterKey.errorType"),
      t("TOPISSUELIST.exceptionInfo"),
      t("TOPISSUELIST.stack"),
      t("TOPISSUELIST.affectedDevices"),
      t("TOPISSUELIST.proportion"),
      t("TOPISSUELIST.issueReportRate"),
      t("REALTIMETOPISSUE.occurNum"),
      t("TOPISSUELIST.samePeriodGrowth"),
      t("TOPISSUELIST.sequentialPeriodGrowth"),
      t("TOPISSUELIST.firstPostTime"),
    ];
    const rows = topIssueEnrichedIssueList.map(item => [
      item.issue.issueId,
      `${window.location.origin}/${item.issue.issueDetailUrlPrefix}/${item.issue.appId}/${item.issue.issueId}?pid=${item.issue.platformId || item.issue.pid}`,
      item.issue.exceptionName,
      item.issue.exceptionMessage,
      item.issue.keyStack,
      formatNum(item.issue.crashUser),
      formatRate(item.issue.crashUser, item.issue.crashDevices),
      formatRate(item.issue.crashUser, item.issue.accessDevices),
      formatEnNumber(item.issue.crashNum),
      formatPercentage(item.issue.prevDayFluctuate),
      formatPercentage(item.issue.prevHourFluctuate),
      item.issue.firstUploadTime,
    ]);
    const filename = `${ t("DATATYPESELECT.hourlyTopIssue") }.xlsx`;
    createExcel([header, ...rows],filename);
  }

  const onLinkClicked = (issue) => {
    reportEvent({
      action: EVENT_ACTIONS.CLICK,
      tp1: '每小时TOP问题点击链接',
      tp6: `${window.location.origin}/${issue.issueDetailUrlPrefix}/${issue.appId}/${issue.issueId}?pid=${issue.platformId || issue.pid}`,
    })
  }

  const columns = [
    {
      title:<div className='table_th'>{ t("TOPISSUELIST.issue") }</div>,
      dataIndex:'problemAbnormal',
      width: 440,
      fixed: 'left',
      render: (issue) => <div>
        <Link
          to={`/${issue.issueDetailUrlPrefix}/${issue.appId}/${issue.issueId}?pid=${issue.platformId || issue.pid}`}
          className={topIssueListScss.link_item}
          rel="noopener noreferrer"
          target="_blank"
          onClick={() => onLinkClicked(issue)}
        >
          <div className="word_blue" style={{display:'flex',alignItems:'center'}}>
            {/* <img src={IssueIdIcon} style={{height: '12px', width: '12px', marginRight: '4px'}} />
            {issue.issueId.substr(0, 8) + '...'}
            <img src={IssueNameIcon} style={{height: '12px', width: '12px', margin: '0 4px'}} />
            <span style={{display:'inline-block', width:'200px', whiteSpace:'nowrap', overflow:'hidden', textOverflow:'ellipsis'}}>{issue.exceptionName}</span> */}

            <Space className={topIssueListScss.error_title} size={4}>
              {<div style={{marginRight: '-2px 4px 0 0'}}>{<IssueIdIcon />}</div>}
              {
                <Tooltip arrow={true}
                  placement="top"
                  title={(<div>{ issue.issueId }</div>)}
                >
                  {<div className={topIssueListScss.error_title_id}>{issue.issueId}</div>}
                </Tooltip>
              }
              {<div style={{margin: '-2px 4px 0'}}>{<IssueNameIcon />}</div>}
              {
                <Tooltip arrow={true}
                  placement="top"
                  title={(<div>{ issue.exceptionName }</div>)}
                >
                  {<div className={topIssueListScss.error_title_type}>{issue.exceptionName}</div>}
                </Tooltip>
              }
            </Space>
          </div>

        </Link>
        <div>
          <CsClampDiv
            lineHeight={1.15}
            lineClamp={3}
            style={{ whiteSpace: 'pre-line', overflowWrap: 'anywhere', lineHeight: '16px' }}
            showButton={!!itemIndexToIsHovering[issue.i]}
          >
              { issue.keyStack ? issue.keyStack : '-'  }
          </CsClampDiv>

        </div>
        <div>
          <TagList
            {...{
              tags: issue.tags, addTag: issue.addTag, delTag: issue.delTag, issueId: issue.issueId, appId:issue.appId, pid: issue.platformId,
              issueVersions: issue.issueVersions,
              firstUploadTimestamp: issue.firstUploadTimestamp,
            }} />
        </div>
      </div>
    },
    {
      title: t("TOPISSUELIST.affectedDevices"),
      sorter: true,
      dataIndex: FIELDS.DEVICES,
      width: isZh() ? 90 : 140,
      align: 'center',
      render: (text, record) => <div>{`${formatNum(record.issue.crashUser)} `}</div>
    },
    {
      title: t("TOPISSUELIST.proportion"),
      dataIndex:'influenceEquipment',
      width: isZh() ? 70 : 80,
      align: 'center',
      render: (text, record) => <div>{`${formatRate(record.issue.crashUser, record.issue.crashDevices)} `}</div>
    },
    {
      title: t("TOPISSUELIST.issueReportRate"),
      dataIndex:'influenceEquipment',
      width: isZh() ? 80 : 140,
      align: 'center',
      render: (text, record) => <div>{`${formatRate(record.issue.crashUser, record.issue.accessDevices)} `}</div>
    },
    {
      title: t("REALTIMETOPISSUE.occurNum"),
      dataIndex: FIELDS.NUM,
      sorter: true,
      align: 'center',
      width: isZh() ? 110 : 86,
      render: (text, record) => <div>{formatEnNumber(record.issue.crashNum)}</div>
    },
    {
      title:<div style={{ display: 'flex', justifyContent: 'center'}}>
        <span style={{paddingRight: 4}}>{ t("TOPISSUELIST.samePeriodGrowth") }</span>
        <Tooltip title={t("TOPISSUELIST.compareWithPrevDaySameHour")}>
          <WrappedTipsIcon />
        </Tooltip></div>,
      sorter: true,
      dataIndex: FIELDS.FLUCTUATE_PREV_DAY,
      width: isZh() ? 100 : 140,
      align: 'center',
      render: (_,{issue}) => (
        <div style={{ position: 'relative' }}>
          { TopIssueUtils.makeFluctuateDom(issue.prevDayFluctuate, issue.prevDayArrowClassName, issue.style, t) }
        </div>
      )
    },
    {
      title:<div style={{display: 'flex', justifyContent: 'center'}}>
        <span style={{paddingRight: 4}}>{ t("TOPISSUELIST.sequentialPeriodGrowth") }</span>
        <Tooltip title={t("TOPISSUELIST.compareWithPrevHour")}>
          <WrappedTipsIcon />
        </Tooltip>
      </div>,
      dataIndex: FIELDS.FLUCTUATE_PREV_HOUR,
      width: isZh() ? 100 : 150,
      sorter: true,
      align: 'center',
      render: (_,{issue}) => (
        <div style={{ position: 'relative' }}>
          { TopIssueUtils.makeFluctuateDom(issue.prevHourFluctuate, issue.prevHourArrowClassName, issue.style, t) }
        </div>
      )
    },
    {
      title: t("TOPISSUELIST.firstPostTime"),
      dataIndex: FIELDS.FIRST_TIME,
      width: isZh() ? 120 : 170,
      align: 'center',
      sorter: true,
      render: (_, {issue}) => <div title={issue.firstUploadTime}>{issue.firstUploadTime}</div>
    },
    {
      title: t("REALTIMETOPISSUE.status"),
      dataIndex:'disposeState',
      width: 120,
      align: 'center',
      render: (_, {issue}) => {
        return <StatusItem
          assigneeList={issue.assigneeList}
          statusNo={issue.statusNo}
          handleChangeStatus={() => { !DemoUtil.checkAndWarnIfDemoApp(demoApp) && handleChangeStatus(issue)}}
        />
      }
    },
    ...(showBugStatusField ? [{
      dataIndex: 'tapdStatus',
      title: <div className='table_th'>
        { !!tapdWorkspaceId && ze('TAPD状态', 'Tapd Status') }
        { !!jiraProjectId && ze('Jira缺陷单状态', 'Jira Issue Status') }
      </div>,
      width: 120,
      align: 'center',
      render: (_, {issue}) => {
        const record = issue;
        const bugInfo = record.bugs && record.bugs[0];
        if (bugInfo) {
          return <IssueTapdBugStatusEx
            currentApp={currentApp}
            issueId={record.issueId}
            tapdBugInfo={record.bugs && record.bugs[0]}
            onBugUnbind={() => onBugUnbind()}
          />;
        } else {
          return <Button
            type='link'
            style={{padding: '0', color: CS_STYLES.PRIMARY_COLOR }}
            onClick={() => onClickUpsertTapdBugByIssueIdList([record.issueId])}>{ze('创建', 'Create')}
          </Button>;
        }
      },
    }] : []),
  ]
  let columnWidth = 0;
  columns.forEach(item => {
    if (item.width) {
      columnWidth += item.width;
    }
  })
  const tableList = renderIssue(actions, demoApp, curIssueData, handleChangeStatus, style, pageType, members, realTimeTopIssueHour, realTimeTopIssueData, sortField, sortOrder);
  return <div className={topIssueListScss.topIssueList}>
    <Spin spinning={realTimeTopIssueLoading}>
      { headArea }
      { isExpanded &&
        <>
          <Row gutter={[10, 6]} align='middle' style={{marginTop: '20px', marginBottom: '20px'}}>
            <Col>
              <Select
                style={{ width: '200px' }}
                options={sortFieldOptions}
                value={realTimeTopIssueSortField || TopIssueSortField.DEVICE_COUNT}
                onChange={value => {
                  (value === 'REPORT_COUNT') && reportEvent({
                    action: EVENT_ACTIONS.CLICK,
                    tp1: '每小时TOP问题-按次数',
                  })
                  actions.fetchRealTimeTopIssueAndSetState({ sortField: value }, true);
                }}
              />
            </Col>
            <Col>
              <Select
                style={{ width: '110px' }}
                options={topIssueCountOptions}
                value={realTimeTopIssueLimit}
                onChange={v => actions.fetchRealTimeTopIssueAndSetState({ limit: v }, true)}
              />
            </Col>
            <Col>
              <VersionHybridSelect
                style={{ width: '320px' }}
                value={versionListValue}
                treeData={versionTree}
                onChange={(v) => { actions.fetchRealTimeTopIssueAndSetState({ version: CsvUtil.singleLineStringify(v) || ALL_VERSION}, true); }}
              />
            </Col>
            <Col>
              <DatePicker
                style={{ width: '220px', cursor: 'pointer' }}
                allowClear={false}
                disabledDate={moment => moment && (moment.isBefore(datePickerMinAvailableMoment) || moment.isAfter(datePickerMaxAvailableMoment))}
                value={moment(realTimeTopIssueHour, 'YYYYMMDDHH')}
                format={(value)=>{return `${value.format('YYYY-MM-DD')} ${formatDateTimeRange(value.hour().toString())}`}}
                showTime={{ format: 'HH:mm', minuteStep: 60 }}
                suffixIcon={
                  <DateSelectIcon style={{ width: '12px', height: '12px' }} />
                }
                onChange={(v) => {
                  const hour = v.format('YYYYMMDDHH');
                  actions.fetchRealTimeTopIssueAndSetState({ hour }, true);
                }}
              />
            </Col>
            { isRegionalDashboard && <Col><CountrySelect
              style={{width: '320px', fontSize: '14px' }}
              value={countryListValue}
              isMultiple={true}
              onChangeWhenDropdownClosed={(v) => actions.fetchRealTimeTopIssueAndSetState({ country: CountryUtil.listToQueryString(v) }, true)}
            /></Col> }
          </Row>
          { isMultipleVersions && <div style={{ margin: '20px 0', width: '100%', color: orange.primary, fontSize: '14px' }}>
            <Space>
              <ExclamationCircleOutlined/>
              <div>{ t('EXCP_OVERVIEW.multipleVersionsWarning')}</div>
            </Space>
          </div> }
          {
            tableList.length ? <>
              <Table
                size="small"
                scroll={{
                  x: `${columnWidth}px`,
                }}
                columns={columns}
                dataSource={tableList}
                showSorterTooltip={false}
                pagination={tableList.length > 10}
                locale={empty}
                rowClassName = { record => {
                  return record.issue.isState1 ? style.done : style.item
                }}
                onRow = { record => {
                  return {
                    onMouseEnter:  () => updateItemIndexToIsHovering(draft => ({ ...draft, [(record.issue).i]: true })),
                    onMouseLeave:  () => updateItemIndexToIsHovering(draft => ({ ...draft, [(record.issue).i]: false }))
                  }
                }}
                onChange = {(pagination, filters, sorter) => {
                  const { order, field } = sorter;
                  setSortField(field || null);
                  setSortOrder(order || null);
                }}
              />
              { tableList.length <= 10 && <div style={{height: '40px'}} />}
            </> : <EmptyData text={ t("REALTIMETOPISSUE.noData") } extraStyle={{paddingTop: 20, paddingBottom: 42}} />
          }
        </>
      }
    </Spin>
  </div>
};

RealTimeTopIssue.propTypes = {
  demoApp: PropTypes.bool,
  collapseIssues: PropTypes.bool,
  realTimeTopIssueData: PropTypes.object,
  style: PropTypes.object,
  handleChangeStatus: PropTypes.func,
  onToggleCollapseIssues: PropTypes.bool,
  pageType: PropTypes.string,
  members: PropTypes.array,
  reduxState: PropTypes.object,
  actions: PropTypes.object,
  isRegionalDashboard: PropTypes.bool, // 是否是国家维度的大盘统计
};

const mapStateToProps = state => ({
  reduxState: state,
});

export default connect(mapStateToProps)(RealTimeTopIssue);
