import React, { useState, useEffect, useMemo } from 'react';
import { bindActionCreators } from 'redux';
import { connect, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import CardHead from 'components/commons/CardHead';
import { Button, Checkbox, Col, DatePicker, Row, Select, Space, Spin, Table, Tooltip, Switch } from 'antd';
import moment from 'moment';
import { PAGE_TYPE_CONVERT, SummaryVmOptions } from 'utils/constants';
import groupBy from 'lodash/collection/groupBy';
import { useTranslation } from 'react-i18next';
import { countryI18n, CountryUtil, countryFlagUrl } from 'utils/country';
import { formatEnNumber } from 'utils/format/format-number';
import CountrySelect from 'components/exception/ProductSummaryPage/CountrySelect';
import { useZhEn } from 'utils/react-hooks/useZhEn';
import uniq from 'lodash/array/uniq';
import { WorldMapChart } from './WorldMapChart';
import { Tab } from 'components/exception/issue';
import XLSX from 'xlsx';
import style from 'components/exception/ProductSummaryPage/style.scss';
import { getSummaryTags } from 'utils/StringUtils';
import {
  selectCurrentAppCustomUserSceneTagLabel,
  selectCurrentAppDatePickerAvailableMomentRange
} from 'utils/selectors/selectors';
import reportEvent, { EVENT_ACTIONS } from 'utils/reportEvent';
import CountryIcon from 'svg/v2/newcs_dashboard_overview_statsbyregion_icon.svg';
import CsDownloadButton from 'components/commons/CsDownloadButton/CsDownloadButton';
import CsCollapseButton from 'components/commons/CsCollapseButton/CsCollapseButton';
import WrappedTipsIcon from 'components/antd-extension/WrappedTipsIcon.jsx';
import CsTabs from 'components/antd-extension/CsTabs';
import _style from './StatByCountry.scss';
import { UpdateTimeTag } from './UpdateTimeTag';
import { DashboardConst } from 'utils/constants/dashboard-const';
import UserSceneTagSelect from 'components/exception/ProductSummaryPage/UserSceneTagSelect';
import VersionHybridSelect from 'components/commons/VersionHybridSelect';
import { getTreeSelectVersionOptions } from 'utils/getTreeSelectVersionOptions';
import { VersionUtil } from 'utils/version-util';
import { orange } from '@ant-design/colors';
import { ExclamationCircleOutlined } from '@ant-design/icons';

const { RangePicker } = DatePicker;

const StatByUserSceneTag = (props) => {
  const { t } = useTranslation();
  const { ze } = useZhEn();

  const [
    datePickerMinAvailableMoment,
    datePickerMaxAvailableMoment,
  ] = useSelector(selectCurrentAppDatePickerAvailableMomentRange);

  const customUserSceneTagLabel = useSelector(selectCurrentAppCustomUserSceneTagLabel);

  const {
    reduxState,
    forceFetchTrigger,
  } = props;
  const currentApp = reduxState.app.get('current').toJS();
  const summary = reduxState.summary.toJS();
  const bigDataRealtimeUpdateMillis = reduxState.summary.get('bigDataRealtimeUpdateMillis');
  const { appId, platformId } = currentApp;
  const { searchParams, selectOptions } = summary;
  const { pageType, rankVersion } = reduxState.summary.get('selectOptions').toJS();
  const reportCategory = PAGE_TYPE_CONVERT[pageType];
  const versionOptions = rankVersion.options;

  const versionTree = useMemo(() => {
    return getTreeSelectVersionOptions(versionOptions);
  }, [versionOptions]);

  const [isExpanded, setIsExpanded] = useState(false);
  const [loading, setLoading] = useState(false);
  const [countryList, setCountryList] = useState([]);
  const [userSceneTagList, setUserSceneTagList] = useState([]);
  const [versionList, setVersionList] = useState([]);
  const [vmStatus, setVmStatus] = useState(0);
  const [minDate, setMinDate] = useState(moment().format('YYYY-MM-DD'));
  const [maxDate, setMaxDate] = useState(moment().format('YYYY-MM-DD'));
  const [countryStatList, setCountryStatList] = useState([]);
  const [isMultiDayResult, setIsMultiDayResult] = useState(false);
  const [fetchTrigger, setFetchTrigger] = useState(0);

  const [isDisplayMap, setIsDisplayMap] = useState(false);
  const [currentTab, setCurrentTab] = useState('EXCP_OVERVIEW.occurNumber');
  const [tabs, setTabs] = useState([]);
  const [tableDom, setTableDom] = useState();

  const tabsMap = {
    'EXCP_OVERVIEW.userCrashRate': 'devicePercentage',
    'EXCP_OVERVIEW.occurNumCrashRate': 'numPercentage',
    'EXCP_OVERVIEW.occurNumber': 'crashNum',
    'EXCP_OVERVIEW.userNumAffect': 'crashUser',
    'EXCP_OVERVIEW.sessionCount': 'accessNum',
    'EXCP_OVERVIEW.userNumConnect': 'accessUser',
    'EXCP_OVERVIEW.userANRRate': 'devicePercentage',
    'EXCP_OVERVIEW.occurNumANRRate': 'numPercentage',
    'EXCP_OVERVIEW.userErrorRate': 'devicePercentage',
    'EXCP_OVERVIEW.occurNumErrorRate': 'numPercentage',
    'EXCP_OVERVIEW.sessionCrashRate': 'sessionPercentage',
    'EXCP_OVERVIEW.sessionNumAffect': 'crashSession',
  };
  const notDisplayMutilDay = ['EXCP_OVERVIEW.userCrashRate', 'EXCP_OVERVIEW.userANRRate', 'EXCP_OVERVIEW.userErrorRate', 'EXCP_OVERVIEW.userNumAffect', 'EXCP_OVERVIEW.userNumConnect'];

  // 切换pageType时设置对应的tabs数组
  useEffect(() => {
    const tabs = getSummaryTags(selectOptions.pageType, searchParams.pid);
    setTabs(tabs);
  }, [searchParams.pid, selectOptions.pageType]);

  // 切换app时重置搜索字段
  useEffect(() => {
    if (!appId) {
      return;
    }
    setCountryList([]);
    setVersionList([]);
    setMinDate(moment().format('YYYY-MM-DD'));
    setMaxDate(moment().format('YYYY-MM-DD'));
    setFetchTrigger(fetchTrigger + 1);
  }, [appId]);
  useEffect(() => {
    if (!appId) {
      return;
    }
    setFetchTrigger(fetchTrigger + 1);
  }, [pageType]);

  useEffect(() => {
    if (!fetchTrigger) {
      return;
    }
    fetchTrend();
  }, [fetchTrigger]);

  useEffect(() => {
    if (!forceFetchTrigger) {
      return;
    }
    fetchTrend();
  }, [forceFetchTrigger]);

  // 当前tab状态变化时切换对应的tableDOM
  useEffect(() => {
    if (!isDisplayMap) {
      setTableDom(allColumnsTableDom);
      return;
    }
    setTableDom(tablesArr.filter(table => table.key === currentTab)[0]);
    // 选择多日数据时，如果当前tab仍在不可展示项则切换至'发生次数'tab
    if (isMultiDayResult && notDisplayMutilDay.includes(currentTab)) {
      setCurrentTab('EXCP_OVERVIEW.occurNumber');
    }
  }, [currentTab, isDisplayMap, countryStatList, isMultiDayResult]);

  async function fetchTrend() {
    setLoading(true);

    const startDate = minDate.replace(/-/g, '');
    const endDate = maxDate.replace(/-/g, '');
    const rsp = await RestHelper.mustPost('/api/app/getTrend', {
      appId,
      platformId,
      subModuleId: searchParams.subModuleId,
      versionList: versionList,
      mergeMultipleVersionsWithInaccurateResult: true,
      startDate,
      endDate,
      type: reportCategory,
      vm: vmStatus,
      needUserSceneTagDimension: true,
      userSceneTagList,
    });
    const trendDataList = CountryUtil.convertTrendCountryGroupIdToName(reduxState.summary, rsp.json.ret.data);
    // 多个日期的数据合并（GET_TREND_SUCC回调那边有类似逻辑，后面可以抽取成公共的）
    const keyToDateTrendList = groupBy(trendDataList, x => x.userSceneTag);
    const countryStatList = Object.entries(keyToDateTrendList).map(([country, dataTrendList]) => dataTrendList.reduce((acc, x) => {
      if (!acc) {
        return x;
      }
      return {
        ...acc,
        accessNum: x.accessNum + acc.accessNum,
        accessUser: x.accessUser + acc.accessUser,
        crashNum: x.crashNum + acc.crashNum,
        crashSession: x.crashSession + acc.crashSession,
        crashUser: x.crashUser + acc.crashUser,
      };
    }, null));
    const enrichedCountryStatList = countryStatList
      .map((x) => {
        const devicePercentage = x.accessUser > 0
          ? (100 * x.crashUser / x.accessUser)
          : 0;
        const numPercentage = x.accessNum > 0
          ? (100 * x.crashNum / x.accessNum)
          : 0;
        const sessionPercentage = x.accessNum > 0
          ? (100 * x.crashSession / x.accessNum)
          : 0;
        return {
          ...x,
          devicePercentage,
          sessionPercentage,
          numPercentage,
        };
      })
      .filter(x => x.userSceneTag !== DashboardConst.USER_SCENE_TAG_ALL) // 去掉全量数据
      .filter(x => x.country !== '-');
    setCountryStatList(enrichedCountryStatList);
    if (countryStatList.length && !isExpanded) {
      setIsExpanded(true);
    }
    setIsMultiDayResult(startDate !== endDate);

    setLoading(false);
  }

  const multiDayNotSupportedDom = <Tooltip
    title={ze(
      '多日查询不支持设备数相关指标（影响设备数、联网设备数等）',
      'If the selected time range spans multiple days and performance considerations are taken into account, some device-related metric queries may not be supported, such as "Affected devices" or "Active devices".',
    )}>
    <Space size={4}>
      <div>-</div>
      <WrappedTipsIcon />
    </Space>
  </Tooltip>;

  const isMultipleVersions = VersionUtil.isMultipleVersions(versionList);

  // 渲染列表的国家地区label，带上国旗图标和中英文名称
  const renderCountryLabelDom = (country, type) => {
    if (type) {
      return !CountryUtil.isDeprecated(country) ? `${countryI18n(country)}` : `${countryI18n(country)} ${ze('(已废弃)', '(Deprecated)')}`;
    }
    return <span style={{ display: 'flex', alignItems: 'center', height: 16 }}>
      {countryFlagUrl(country)
        ? (<img style={{ width: '20px', marginRight: '5px' }} alt="Icon" src={countryFlagUrl(country)} />)
        : (<span style={{ width: '20px', marginRight: '5px' }}> </span>)}
      {!CountryUtil.isDeprecated(country) ? `${countryI18n(country)}` : `${countryI18n(country)} ${ze('(已废弃)', '(Deprecated)')}`}
    </span>;
  };

  const userSceneTagLabelSingular = customUserSceneTagLabel || ze('场景', 'Scene');
  const userSceneTagLabelPlural = customUserSceneTagLabel || ze('场景', 'Scenes');

  const allColumns = [{
    title: userSceneTagLabelSingular,
    width: '200px',
    key: 'userSceneTag',
    dataIndex: 'userSceneTag',
    render: (text, record) => {
      const { userSceneTag } = record;
      return userSceneTag !== DashboardConst.USER_SCENE_TAG_TOTAL_OF_ALL_TAGS
        ? userSceneTag
        : <Tooltip title={ze(`仅统计所有带${userSceneTagLabelSingular}的上报数据。不带${userSceneTagLabelSingular}的上报数据不包含在内。`, `Reports without ${userSceneTagLabelSingular} are not included.`)}>
          <Space size={4}>
            <div>{ ze(`全部${userSceneTagLabelPlural}合计`, `All ${userSceneTagLabelPlural}`) }</div>
            <WrappedTipsIcon />
          </Space>
        </Tooltip>;
    },
    sorter: (a, b) => (a.userSceneTag || '').localeCompare(b.userSceneTag),
  }, ...['EXCP_OVERVIEW.userCrashRate', 'EXCP_OVERVIEW.userANRRate', 'EXCP_OVERVIEW.userErrorRate', 'EXCP_OVERVIEW.deviceOomRate'].map(tab => ({
    title: t(tab),
    width: '200px',
    align: 'right',
    key: tab,
    dataIndex: 'devicePercentage',
    render: (text, record) => {
      if (isMultiDayResult) {
        return multiDayNotSupportedDom;
      }
      const { devicePercentage } = record;
      return `${devicePercentage.toFixed(2)} %`;
    },
    sorter: (a, b) => a.devicePercentage - b.devicePercentage,
  })), ...['EXCP_OVERVIEW.occurNumCrashRate', 'EXCP_OVERVIEW.occurNumANRRate', 'EXCP_OVERVIEW.occurNumErrorRate', 'EXCP_OVERVIEW.numOomRate'].map(tab => ({
    title: t(tab),
    width: '200px',
    align: 'right',
    key: tab,
    dataIndex: 'numPercentage',
    render: (text, record) => {
      const { numPercentage } = record;
      return `${numPercentage.toFixed(2)} %`;
    },
    sorter: (a, b) => a.numPercentage - b.numPercentage,
  })), {
    title: t('EXCP_OVERVIEW.sessionCrashRate'),
    width: '200px',
    align: 'right',
    key: 'EXCP_OVERVIEW.sessionCrashRate',
    dataIndex: 'sessionPercentage',
    render: (text, record) => {
      const { sessionPercentage } = record;
      return `${sessionPercentage.toFixed(2)} %`;
    },
    sorter: (a, b) => a.sessionPercentage - b.sessionPercentage,
  }, {
    title: t('EXCP_OVERVIEW.occurNumber'),
    width: '200px',
    align: 'right',
    key: 'EXCP_OVERVIEW.occurNumber',
    dataIndex: 'crashNum',
    defaultSortOrder: 'descend',
    render: (text, record) => formatEnNumber(text),
    sorter: (a, b) => a.crashNum - b.crashNum,
  }, {
    title: t('EXCP_OVERVIEW.sessionNumAffect'),
    width: '200px',
    align: 'right',
    key: 'EXCP_OVERVIEW.sessionNumAffect',
    dataIndex: 'crashSession',
    render: (text, record) => {
      if (isMultiDayResult) {
        return multiDayNotSupportedDom;
      }
      return formatEnNumber(text);
    },
    sorter: (a, b) => a.crashSession - b.crashSession,
  }, {
    title: t('EXCP_OVERVIEW.userNumAffect'),
    width: '200px',
    align: 'right',
    key: 'EXCP_OVERVIEW.userNumAffect',
    dataIndex: 'crashUser',
    render: (text, record) => {
      if (isMultiDayResult) {
        return multiDayNotSupportedDom;
      }
      return formatEnNumber(text);
    },
    sorter: (a, b) => a.crashUser - b.crashUser,
  }, {
    title: ze(`进入${customUserSceneTagLabel || '场景'}次数`, `${customUserSceneTagLabel || 'Scene'} Entered`),
    width: '200px',
    align: 'right',
    key: 'EXCP_OVERVIEW.sessionCount',
    dataIndex: 'accessNum',
    render: (text, record) => formatEnNumber(text),
    sorter: (a, b) => a.accessNum - b.accessNum,
  }, {
    title: t('EXCP_OVERVIEW.userNumConnect'),
    width: '200px',
    align: 'right',
    key: 'EXCP_OVERVIEW.userNumConnect',
    dataIndex: 'accessUser',
    render: (text, record) => {
      if (isMultiDayResult) {
        return multiDayNotSupportedDom;
      }
      return formatEnNumber(text);
    },
    sorter: (a, b) => a.accessUser - b.accessUser,
  }];
  // 单独展示列表时TableDom
  const allColumnsTableDom = <Table
    size='small'
    columns={[allColumns[0], ...allColumns.filter(col => tabs.includes(col.key))]}
    key='allColumns'
    dataSource={countryStatList}
    showSorterTooltip={false}
    pagination={{
      showSizeChanger: true,
      defaultPageSize: 5,
      pageSizeOptions: [5, 10, 20, 50, 100],
    }}
    style={{ flex: 1 }}
  />;

  // table组件中defaultSortOrder属性只在第一次渲染时生效，组件挂载时预生成所有指标的TableDOM
  const tablesArr = allColumns.slice(1).map(col => [allColumns[0], { ...col, defaultSortOrder: 'descend' }])
    .map(columns => <Table
      size='small'
      columns={columns}
      key={columns[1].key}
      dataSource={countryStatList}
      pagination={countryStatList.length < 10 ? false : {
        showSizeChanger: false,
        pageSize: 15,
      }}
      style={{ flex: 1 }}
    />);

  function createExcel(data, title) {
    const { utils } = XLSX;
    const ws_name = 'sheet1';
    const wb = utils.book_new();
    /* make worksheet */
    const ws = utils.aoa_to_sheet(data);
    /* Add the worksheet to the workbook */
    XLSX.utils.book_append_sheet(wb, ws, ws_name);
    XLSX.writeFile(wb, title);
  }

  const cardTitle = ze(`按${customUserSceneTagLabel || '场景'}维度统计详情`, `Stats By ${customUserSceneTagLabel || 'Scenes'}`);

  const downloading = (e) => {
    e.stopPropagation();
    const header = [
      ze('国家或地区', 'Country/Region'),
      t('EXCP_OVERVIEW.userCrashRate'),
      t('EXCP_OVERVIEW.occurNumCrashRate'),
      t('EXCP_OVERVIEW.occurNumber'),
      t('EXCP_OVERVIEW.userNumAffect'),
      t('EXCP_OVERVIEW.sessionCount'),
      t('EXCP_OVERVIEW.userNumConnect'),
    ];
    const rows = countryStatList.map(item => [
      item.country !== '-1' ? renderCountryLabelDom(item.country, 'download') : t('EXCP_OVERVIEW.全部国家地区'),
      `${item.devicePercentage.toFixed(2)} %`,
      `${item.numPercentage.toFixed(2)} %`,
      formatEnNumber(item.crashNum),
      formatEnNumber(item.crashUser),
      formatEnNumber(item.accessNum),
      formatEnNumber(item.accessUser),
    ]);
    const filename = `${cardTitle}.xlsx`;
    createExcel([header, ...rows], filename);
  };

  return <Spin spinning={loading}>
    <CardHead
      title={cardTitle}
      hideDivider={!isExpanded}
      svgIcon={<CountryIcon />}
      onClick={() => {
        if (!isExpanded) {
          reportEvent({
            action: EVENT_ACTIONS.CLICK,
            tp1: '按场景维度统计详情',
          });
        }
        setIsExpanded(prev => !prev);
      }}
    >
      <div style={{ display: 'flex', flexGrow: '1', alignItems: 'center', justifyContent: 'flex-end' }}>
        <UpdateTimeTag updateTime={bigDataRealtimeUpdateMillis} />
        <CsDownloadButton
          style={{ margin: '0px 24px' }}
          onClick={(e) => downloading(e)}
        />

        <CsCollapseButton
          extraStyle={{ width: '14px', height: '14px' }}
          isCollapsed={!isExpanded}
          enableHover={true}
          onClick={(e) => {
            e.stopPropagation();
            if (!isExpanded) {
              reportEvent({
                action: EVENT_ACTIONS.CLICK,
                tp1: '按场景维度统计详情',
              });
            }
            setIsExpanded(!isExpanded);
          }}
        />
      </div>
    </CardHead>
    { isExpanded && <div>
      <Row gutter={10} align='middle' style={{ margin: '20px -5px' }}>
        <Col>
          <VersionHybridSelect
            treeData={versionTree}
            style={{ width: '320px' }}
            value={versionList}
            onChange={v => setVersionList(v)}
          />
        </Col>
        <Col>
          <RangePicker
            style={{ width: '220px' }}
            disabledDate={moment => moment && (moment.isBefore(datePickerMinAvailableMoment) || moment.isAfter(datePickerMaxAvailableMoment))}
            allowClear={false}
            allowEmpty={[false, false]}
            showToday={false}
            placeholder={[ze('日期范围', 'Time Range')]}
            value={[moment(minDate), moment(maxDate)]}
            onChange={(dates) => {
              const [minDate, maxDate] = (dates || [null, null]).map(x => x && x.format('YYYY-MM-DD'));
              setMinDate(minDate);
              setMaxDate(maxDate);
            }}
          />
        </Col>
        <Col>
          <Select
            style={{ width: '120px' }}
            options={SummaryVmOptions}
            value={vmStatus}
            onChange={v => setVmStatus(v)}
          />
        </Col>
        <Col>
          <UserSceneTagSelect
            isMultiple={true}
            style={{ width: '320px' }}
            value={userSceneTagList}
            onChangeWhenDropdownClosed={v => setUserSceneTagList(v)}
          />
        </Col>
        <Col>
          <Button
            type='primary'
            onClick={() => {
              fetchTrend();
            }}
          >{ t('common.查询') }</Button>
        </Col>
      </Row>
      { isMultipleVersions && <div style={{ margin: '20px 0', width: '100%', color: orange.primary, fontSize: '14px' }}>
        <Space>
          <ExclamationCircleOutlined/>
          <div>{ t('EXCP_OVERVIEW.multipleVersionsWarning')}</div>
        </Space>
      </div> }
      <div className={_style.statByCountry}>
        {tableDom}
      </div>
    </div> }
  </Spin>;
};

StatByUserSceneTag.propTypes = {
  reduxState: PropTypes.object,
  actions: PropTypes.object,
};

const mapStateToProps = state => ({
  reduxState: state,
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({
    // ...productActions,
    // ...appActions,
    // ...globalActions,
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(StatByUserSceneTag);
