/**
 * 全局状态信息
 */

import { handleActions } from 'redux-actions';
import {
  SHOW_LOADING,
  HIDE_LOADING,
  SHOW_BULLETIN,
  HIDE_BULLETIN,
  ACTIVE_POP_ALERT,
  UNACTIVE_POP_ALERT,
  SET_ERROR,
  RESET_ERROR,
  LOCATION_CHANGE,
  DEPARTMENT_LIST_SUCC,
  DEPARTMENT_ITEM_SUCC,
  ACTIVE_UPLOAD_POP_ALERT,
  INACTIVE_UPLOAD_POP_ALERT,
  CHANGE_UPLOAD_POP_ALERT_INFO,
  ACTIVE_APPLY_POP_ALERT,
  UNACTIVE_APPLY_POP_ALERT,
} from 'store/actions';
import { checkIsDemo, parseSearch } from 'utils/helper';
import { fromJS } from 'immutable';
import { initialState } from './globalInitialState';
import produce from 'immer';

const TAG = '[globalReducer]::';

const CRASHSIGHT_LOCAL_STORAGE_KEY = 'CrashSight';

export default handleActions({
  SET_PARTIAL_LOCAL_STORAGE: (state, action) => {
    const { payload } = action;
    if (!payload) {
      return state;
    }
    const oldLocalStorage = state.get('localStorage').toJS();
    const newLocalStorage = produce(oldLocalStorage, (draft) => {
      return {
        ...draft,
        ...payload,
      };
    });
    try {
      window.localStorage.setItem(CRASHSIGHT_LOCAL_STORAGE_KEY, JSON.stringify(newLocalStorage));
    } catch (e) {
      console.error(e);
    }
    return state.set('localStorage', fromJS(newLocalStorage));
  },

  /**
   * 加载中进度
   */
  [SHOW_LOADING]: (state) => state.set('loading', true),
  [HIDE_LOADING]: (state) => state.set('loading', false),

  [SHOW_BULLETIN]: (state) => state.set('isAnnouncementShown', true),
  [HIDE_BULLETIN]: (state) => state.set('isAnnouncementShown', false),


  [SET_ERROR]: (state, action) => state.set('error', action.payload),

  [RESET_ERROR]: (state) => state.set('error', initialState.get('error')),

  /**
   * 激活弹出窗
   */
  [ACTIVE_POP_ALERT]: (state, action) => {
    console.log(`${TAG} action: `, action);
    return state.update('popAlertData', (popAlertData) => popAlertData.merge(action.payload)).set('popAlertStatus', true);
  },
  /**
   * 关闭弹出窗
   */
  [UNACTIVE_POP_ALERT]: (state) => state.set('popAlertStatus', false).update('popAlertData', (popAlertData) => popAlertData.clear()),
  /**
   * 激活产品申请激活弹出框
   */
  [ACTIVE_APPLY_POP_ALERT]: (state, action) => {
    console.log(`${TAG} action: `, action);
    return state.update('applyPopAlertData', (applypopAlertData) => applypopAlertData.merge(action.payload)).set('applyPopAlertStatus', true);
  },
  /**
   * 关闭产品申请激活弹出框
   */
  [UNACTIVE_APPLY_POP_ALERT]: (state) => state.set('applyPopAlertStatus', false).update('applyPopAlertData', (popAlertData) => popAlertData.clear()),

  [ACTIVE_UPLOAD_POP_ALERT]: (state, action) => state.set('uploadPopAlertStatus', true).update('uploadPopAlertData', (uploadPopAlertData) => uploadPopAlertData.merge(action.payload)),

  [INACTIVE_UPLOAD_POP_ALERT]: (state) => state.set('uploadPopAlertStatus', false).update('uploadPopAlertData', (uploadPopAlertData) => uploadPopAlertData.clear()),

  [CHANGE_UPLOAD_POP_ALERT_INFO]: (state, action) => {
    const tip = action.payload;
    return state.updateIn(['uploadPopAlertData', 'tip'], () => tip);
  },

  /**
   * 成功获取符号表大小的信息
   * TODO: 需要得到那边获取得到的数据进行检验，设置到本地的dsymsSize的inirtialState的大小配置中
   */
  DSYMSSIZE_GET_SUCC: (state, action) => {
    console.log('这是dsymsSize的主要请求路径和地址', action);
    return state.set('dsymsSize', action.response.size);
  },
  /**
   * 成功获取告警配置信息
   */
  ALARMCONFIG_LIST_SUCC: (state, action) => {
    const data = action.response.data.map(x => ({
      ...x,
      crashImei: x.crashImeiFluctuate ? x.crashImeiFluctuate : 0,
      crashRate: x.crashRate ? x.crashRate : 0,
      fluctuate: x.crashRateFluctuate ? x.crashRateFluctuate : 0,
      otherInfo: x.otherInfo || {},
    }));

    return state.set('reportConfig', fromJS(data));
  },

  /**
   * ajax请求失败
   */
  FETCH_FAILURE: (state) => state.set('loading', false),


  /**
   *事件统计
   */
  REPORT_GET_SUCC: (state) => state.set('report', true),

  REPORT_URL_CHANGE: (state, action) => {
    return state.set('reportUrl', action.payload);
  },

  /**
   * 检查是否是demo产品
   * @param state
   * @param action
   * @returns {*}
   * @constructor
   */
  CHECK_IS_DEMO: (state, action) => {
    return state.set('isDemoApp', checkIsDemo(action.payload));
  },

  /**
   * leftmenu state change
   */
  CHANGE_LEFTMENU_STATE: (state, action) => {
    return state.set('leftMenuState', action.payload);
  },

  [DEPARTMENT_LIST_SUCC]: (state, action) => {
    const { data } = action.response;
    if (!data || data.length === 0) {
      return state;
    }
    const options = data.map((item) => {
      return { label: item.name, value: `${item.id}` };
    });
    return state.set('departments', fromJS(options));
  },

  [DEPARTMENT_ITEM_SUCC]: (state, action) => {
    const { data } = action.response;
    if (!data || data.length === 0) {
      return state;
    }
    const options = data.map((item) => {
      return { label: item.name, value: `${item.id}` };
    });
    return state.update('subDepartments', (items) => items.merge({ [action.params.itemId]: options }));
  },

  [LOCATION_CHANGE]: (state, action) => {
    const { location } = action.payload;
    const query = parseSearch(location);
    if (query.rows !== undefined && parseInt(query.rows) !== state.get('rows')) {
      window.localStorage.setItem('pageRows', query.rows);
      return state.set('rows', parseInt(query.rows));
    }
    return state;
  },

  SET_PAGE_ROWS: (state, action) => {
    window.localStorage.setItem('pageRows', action.payload || 10);
    return state.set('rows', parseInt(action.payload || 10));
  },
}, initialState);
