import React, { useState, useEffect, useMemo } from 'react';
import Cookies from 'js-cookie';
import { ze } from 'utils/zhEn';
import reportEvent, { EVENT_ACTIONS } from 'utils/reportEvent';
import scss from './CsV2Switch.scss';
import { AiOutlineSwap } from "react-icons/ai";

const V1_COOKIE_KEY = 'cs_legacy_v1_site';

const CsV2Switch = ({
}) => {
  const cookieValue = Cookies.get(V1_COOKIE_KEY);
  const isV2 = cookieValue !== 'true';

  return <div>
    <a
      onClick={async () => {
        if (isV2) {
          await reportEvent({
            action: EVENT_ACTIONS.CLICK,
            tp1: '回到1.0旧版',
          });
          Cookies.set(V1_COOKIE_KEY, 'true', { expires: 365 });
        } else {
          await reportEvent({
            action: EVENT_ACTIONS.CLICK,
            tp1: '切换2.0新版',
          });
          Cookies.remove(V1_COOKIE_KEY);
        }
        window.location.reload();
      }}
    >
      <div
        className={scss.cs_v2_switch}
      >
        <div>{ isV2 ? ze('回到1.0旧版', 'Switch to Old UI') : ze('切换2.0新版', 'Go to New UI') }</div>
        <AiOutlineSwap style={{ transform: 'scaleX(-1)' }}/>
      </div>
    </a>
  </div>;
};

export default CsV2Switch;
