import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Checkbox, Divider, Space, Tooltip } from 'antd';
import { SummaryVmTypeMultipleSelectOptions } from 'utils/constants';
import { ze } from 'utils/zhEn';
import { VmTypeInt } from 'utils/constants/vm-type';
import { useTranslation } from 'react-i18next';
import { EnvUtil } from 'utils/env-util';
import reportEvent, { EVENT_ACTIONS } from 'utils/reportEvent';
import WrappedTipsIcon from 'components/antd-extension/WrappedTipsIcon.jsx';


const VmTypeCheckboxGroup = (props) => {
  const {
    value = [],
    onChange = (v) => {},
  } = props;
  const { t } = useTranslation();

  const isAllChecked = value.some(x => x === VmTypeInt.ALL);
  const valueWithoutAll = value.filter(x => x !== VmTypeInt.ALL);

  const options = SummaryVmTypeMultipleSelectOptions;

  return <Space size={0}>
    <Checkbox
      checked={isAllChecked}
      onChange={(e) => {
        // 点击全量，进行上报
        if (e.target.checked) {
          reportEvent({
            action: EVENT_ACTIONS.CLICK,
            tp1: `异常概览-${ze('全量', 'All')}`,
          })
        }
        onChange([VmTypeInt.ALL]);
      }}
    >{ ze('全量', 'All') }</Checkbox>
    <Divider type='vertical'/>
    <Checkbox.Group
      value={valueWithoutAll}
      options={options}
      onChange={(v) => {
        // 点击其他类型，进行上报
        const newValue = v.filter(x => !valueWithoutAll.includes(x));
        if (newValue.length) {
          const newType = SummaryVmTypeMultipleSelectOptions.find(item => item.value === newValue[0]);
          reportEvent({
            action: EVENT_ACTIONS.CLICK,
            tp1: `异常概览-${newType.label}`,
          })
        }

        if (v.length >= SummaryVmTypeMultipleSelectOptions.length) {
          onChange([VmTypeInt.ALL]);
        } else {
          onChange(v);
        }
      }}
    />
  </Space>;
};

VmTypeCheckboxGroup.propTypes = {
  value: PropTypes.array,
  onChange: PropTypes.func,
};

export default VmTypeCheckboxGroup;
