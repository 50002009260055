import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Tab, SystemInfoTable, IssueTrend, Behavior, DeviceStats
} from 'components/exception/issue';
import EmptyData from 'components/commons/EmptyData';
import { PLATFORM_ID } from 'utils/constants';
import { isGameConsole, isMobile, isPcOrLinux, isPlaystation, isSwitch } from 'utils/platform';
import commonScss from 'styles/common.scss';
import _style from './style.scss';
import {connect} from "react-redux";
import { DownloadOutlined } from '@ant-design/icons';
import {Button} from "antd";
import XLSX from 'xlsx';
import { withTranslation } from 'react-i18next';
import IssueRegroup from 'components/exception/issue/IssueRegroup';
import { PureRender } from 'components/commons';
import {
  selectFrontendWebVolatileConfigValueByKey, selectHasSpecifiedPermissionInCurrentApp,
} from 'utils/selectors/selectors';
import {
  FrontendWebVolatileConfigKeys,
} from 'utils/constants/frontend-web-volatile-config';
import IssueFeatureAnalysis from 'components/exception/issue/IssueFeatureAnalysis';
import { ze } from 'utils/zhEn';
import { ServerAppSettings } from 'utils/server-app-settings';
import { ExceptionCategoryUtil } from 'utils/exception-category';
import { fetchIssueTrendAccessDeviceTrend } from 'reducers/issue/issueActions';
import reportEvent, { EVENT_ACTIONS } from 'utils/reportEvent';
import CsTabs from 'components/antd-extension/CsTabs';
import { PiCaretDoubleDownBold } from "react-icons/pi";
import CsCollapseButton from 'components/commons/CsCollapseButton/CsCollapseButton';
import MergedIssueList from '../MergedIssueList/MergedIssueList';
import { MERGE_TYPE_IS_MERGE_ISSUE } from 'utils/constants/merge-issue-type';
import { RolePermission } from 'utils/constants/role-permission';

function createExcel(data,title){
  const { utils } = XLSX;
  var ws_name = "sheet1";
  const wb= utils.book_new();
  /* make worksheet */
  var ws = utils.aoa_to_sheet(data);

  /* Add the worksheet to the workbook */
  XLSX.utils.book_append_sheet(wb, ws, ws_name);
  XLSX.writeFile(wb, title);
}

@connect((state) => {
  return {
    frontendWebVolatileConfig: state.user.get('frontendWebVolatileConfig'),
    reduxState: state,
    currentApp: state.app.get('current'),
    selectFrontendWebVolatileConfigValueByKey: (key) => selectFrontendWebVolatileConfigValueByKey(state, key),
  };
}, (dispatch) => ({ dispatch }))
@PureRender
@withTranslation()
export default class CommonInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isCollapsed: false,
      container: false,
      device: '',
    };
  }

  /**
   * 处理图表的数据，输入是接口数据，输出是{data, labels, sum}
   */
  parseData(data) {
    // 如果数据不为空列表
    const dataArr = [];
    const labelsArr = [];
    let rateArr = [];
    const alldataArr = [];
    const alllabelsArr = [];
    let allrateArr = [];
    let sum = 0;

    if (data) {
      if (data.length === 1 && data[0].name === '其他') {
        return { dataArr, labelsArr, sum };
      }
      for (let i = 0; i < data.length + 5; i++) {
        // 多执行5次
        const temp = data[i];
        if (temp && temp.n > 0) {
          sum += temp.n;
          if (temp.name !== '其他') {
            dataArr.push(temp.n);
            labelsArr.push(temp.name || ze('未知', 'Unknown'));
            alldataArr.push(temp.n);
            alllabelsArr.push(temp.name|| ze('未知', 'Unknown'));
          }
        } else {
          dataArr.push('');
          labelsArr.push('');
        }
      }
      // debugger;
      dataArr.length = 5;
      labelsArr.length = 5;

      rateArr = dataArr.map((d) => ((100 * d) / sum).toFixed(2));
      allrateArr = alldataArr.map((d) => ((100 * d) / sum).toFixed(2));
    }
    return {
      dataArr, rateArr, labelsArr,alldataArr,alllabelsArr,allrateArr, sum,
    };
  }

  handleChangeState(val) {
    this.setState({
      device: val,
    });
  }

  handleChangeTab(name) {
    const {
      dispatch, actions, issue, pid, crashDataType,
    } = this.props;
    dispatch({
      type: 'CHANGE_TAB',
      value: name,
      tab: 1,
    });
    this.setState({
      container: false,
    });
    if (name === 'TAB1.postTrend') {
      const task = [
        actions.getIssueTrend(),
        actions.fetchIssueTrendAccessDeviceTrend(),
        actions.fetchCountryGroupConfig(),
      ];
      actions.showLoading();
      Promise.all(task).then(actions.hideLoading).catch(actions.hideLoading);
    }

    const tabName = name.split('.')?.[1];
    ['问题重分类', '特征分析'].includes(tabName) && reportEvent({
      action: EVENT_ACTIONS.CLICK,
      tp1: tabName,
    });
  }

  getServerAppSettings() {
    const { appId, reduxState } = this.props;
    return reduxState.app.get('appIdToServerAppSettings')[appId] || {};
  }

  getIsNotNetmarble() {
    const { currentApp } = this.props;
    const accountId = currentApp.get('accountId');
    return accountId !== '10050690181558001';
  }

  getEnableIssueRegroup() {
    const { pid, exceptionType } = this.props;
    const isSupported = (!isGameConsole(pid) && ExceptionCategoryUtil.isCrash(exceptionType))
      || ((isPcOrLinux(pid) || this.getIsEnableErrorRetrace()) && ExceptionCategoryUtil.isError(exceptionType));
    return isSupported && this.getIsNotNetmarble(); // 对netmarble账户下的所有项目，禁止issue regroup
  }

  getIsEnableErrorRetrace() {
    const serverAppSettings = this.getServerAppSettings();
    return serverAppSettings[ServerAppSettings.keys.enableErrorRetrace];
  }

  getIsEnableIssueMerge() {
    const serverAppSettings = this.getServerAppSettings();
    return serverAppSettings[ServerAppSettings.keys.enableIssueMerge];
  }

  getIsEnableCustomFeature() {
    const { selectFrontendWebVolatileConfigValueByKey, appId } = this.props;
    const serverAppSettings = this.getServerAppSettings();
    const enableFeatureAnalysis = serverAppSettings[ServerAppSettings.keys.enableFeatureAnalysis];

    const customFeatureAppIdWhitelist = (selectFrontendWebVolatileConfigValueByKey(
      FrontendWebVolatileConfigKeys.CUSTOM_FEATURE_APP_ID_WHITELIST) || '')
    .split(',')
    .map(x => x.trim());
    return enableFeatureAnalysis || customFeatureAppIdWhitelist.includes(appId);
  }

  renderTab(tabs, style, changeIssueTrendTag, getIssueTrend, changeIssueTrendVersion, changeIssueTrendCountryList, crashDataType) {
    const {
      appId, issue, pid, showLoading, hideLoading, actions, issueId, exceptionType, path, reduxState
    } = this.props;

    let componentKey = null;
    switch (issue.get('tab1')) {
      case 'TAB1.hardware': {
        return (
          <div>
            <DeviceStats
                appId={appId}
                platformId={pid}
                issueHash={issueId}
            />
          </div>
        );
      }
      case 'TAB1.postTrend': {
        return (
          <IssueTrend
            platformId={pid}
            changeIssueTrendTag={changeIssueTrendTag}
            issue={issue}
            getIssueTrend={getIssueTrend}
            changeIssueTrendVersion={changeIssueTrendVersion}
            changeIssueTrendCountryList={changeIssueTrendCountryList}
            showLoading={showLoading}
            hideLoading={hideLoading}
            crashDataType={crashDataType} />
        );
      }
      case 'TAB1.用户行为': {
        return (
          <Behavior
            appId={appId}
            platformId={pid}
            issueHash={issueId}
            typeAnalysis='UserBehavior'
          />
        );
      }
      case 'TAB1.行为分析': {
        return (
          <Behavior
            appId={appId}
            platformId={pid}
            issueHash={issueId}
            typeAnalysis='CustomizedData'
          />
        );
      }
      case 'TAB1.问题重分类': {
        componentKey = 'Issue-regroup';
        return (
          <IssueRegroup
            key={componentKey}
            appId={appId}
            platformId={pid}
            issueHash={issueId}
            issue={issue}
            exceptionType={exceptionType}
            path={path}
          />
        );
      }
      case 'TAB1.问题子分类': {
        componentKey = 'subproblem-analysis';
        return (
          <IssueRegroup
            key={componentKey}
            appId={appId}
            platformId={pid}
            issueHash={issueId}
            issue={issue}
            exceptionType={exceptionType}
            path={path}
            isFromSubIssueTab={true}
          />
        );
      }
      case 'TAB1.合并问题': {
        return (
          <MergedIssueList
            appId={appId}
            platformId={pid}/>
        );
      }
      case 'TAB1.特征分析': {
        return <IssueFeatureAnalysis
          path={path}
        />;
      }
      case 'TAB1.version':
      default: {
        return (
          <SystemInfoTable
            versions={issue.get('issueVersions')}
            appId={appId}
            platformId={pid}
            issueId={issueId}
            reduxState = {reduxState}
          />
        );
      }
    }
  }

  renderFormatter(_data) {
    return (params) => {
      const index = params[0].dataIndex;
      const i = 4 - index;

      if (!_data.dataArr[i]) {
        return '-'
          + '<br>'
          + `${ this.props.t("SYMBOLINFOTABLE.crashNumFormatter") } ： -`
          + '<br>'
          + `${ this.props.t("SYMBOLINFOTABLE.crashNumRateFormatter") } ： -`;
      }

      return `${_data.labelsArr[4 - index]
      }<br>`
        + `${ this.props.t("SYMBOLINFOTABLE.crashNumFormatter") } ： ${_data.dataArr[i]
        }<br>`
        + `${ this.props.t("SYMBOLINFOTABLE.crashNumRateFormatter") } ： ${((100 * _data.dataArr[i]) / _data.sum).toFixed(2)}%`;
    };
  }

  render() {
    const {
      issue, style, changeIssueTrendTag, getIssueTrend, changeIssueTrendVersion, changeIssueTrendCountryList, crashDataType, pid, exceptionType,
      t,
      reduxState,
    } = this.props;

    const isSuperAdmin = reduxState.user.get('current').get('isSuper');
    const hasViewDashboardPermission = selectHasSpecifiedPermissionInCurrentApp(reduxState, RolePermission.VIEW_DASHBOARD);

    const tabList = [{
      value: 'TAB1.version',
      available: true,
    }, {
      value: 'TAB1.postTrend',
      available: hasViewDashboardPermission && !isSwitch(pid),
    }, {
      value: 'TAB1.hardware',
      available: !isGameConsole(pid) || ExceptionCategoryUtil.isError(exceptionType),
    }, {
      value: 'TAB1.用户行为',
      available: !isGameConsole(pid) || ExceptionCategoryUtil.isError(exceptionType),
    }, {
      value: 'TAB1.行为分析',
      available: this.getIsNotNetmarble() && !isGameConsole(pid) || ExceptionCategoryUtil.isError(exceptionType), // netmarble不开放自定义分析
    }, {
      value: 'TAB1.特征分析',
      available: this.getIsEnableCustomFeature(),
    }, {
      value: 'TAB1.问题子分类',
      available: this.getEnableIssueRegroup(),
    }, {
      label: this.getEnableIssueRegroup() ? t('TAB1.问题重分类') : `${t('TAB1.问题重分类')}（超管）`,
      value: 'TAB1.问题重分类',
      available: isSuperAdmin || this.getEnableIssueRegroup(),
    }, {
      value: 'TAB1.合并问题',
      available: this.getIsEnableIssueMerge() && (issue?.toJS()?.esMap?.mergeIssueType === MERGE_TYPE_IS_MERGE_ISSUE),
    }].filter(x => x.available)
      .map(x => ({ label: x.label || t(x.value), key: x.value }));

    return (
      <div
        className={`${style.issue_container} ${this.state.container ? style.unactive : ''}`}
        style={{ padding: '14px 24px' }}
      >
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <CsTabs
            style={{ flexGrow: 1 }}
            bigSize={true}
            activeKey={issue.get('tab1') || tabList[0].key}
            onChange={key => this.handleChangeTab(key)}
            items={tabList}
          />
          <CsCollapseButton
            isCollapsed={this.state.isCollapsed}
            onClick={() => this.setState({ isCollapsed: !this.state.isCollapsed })}
          />
        </div>
        { !this.state.isCollapsed && <div style={{ margin: '16px 8px 0 8px' }}>
          { this.renderTab.call(this, tabList, _style, changeIssueTrendTag, getIssueTrend, changeIssueTrendVersion, changeIssueTrendCountryList, crashDataType) }
        </div> }
      </div>
    );
  }
}

CommonInfo.propTypes = {
  reduxState: PropTypes.object,
  issue: PropTypes.object.isRequired,
  style: PropTypes.object,
  pid: PropTypes.string,
  showLoading: PropTypes.func,
  hideLoading: PropTypes.func,
  actions: PropTypes.object,
  dispatch: PropTypes.func,
  changeIssueTrendTag: PropTypes.func,
  getIssueTrend: PropTypes.func,
  changeIssueTrendVersion: PropTypes.func,
  changeIssueTrendCountryList: PropTypes.func,
  exceptionType: PropTypes.string.isRequired,
  path: PropTypes.string,
  currentApp: PropTypes.object,
  frontendWebVolatileConfig: PropTypes.object,
};
