// 获取树形结构版本下拉的选项
import { groupBy } from "lodash";
import { VersionUtil } from 'utils/version-util';

const VERSION_SEPARATOR = '.';
const WILDCARD_CHARACTER = VersionUtil.WILDCARD_CHARACTER;

/**
 *
 * @param {{ value: String }[]}versionList
 * @returns {*}
 */
export function getTreeSelectVersionOptions(versionList) {
  /**
   * @param {{value: String, label: String, splitSections: String[]}[]} optionList
   */
  function recursiveRoutine(optionList, childIndex = 0) {
    const groupByDict = groupBy(optionList, (x) => {
      if (childIndex < x.splitSections.length - 1) {
        return JSON.stringify([
          true,
          x.splitSections.slice(0, childIndex + 1).join(VERSION_SEPARATOR) + VERSION_SEPARATOR + WILDCARD_CHARACTER,
          x.splitSections[childIndex],
        ]);
      }
      return JSON.stringify([
        false,
        x.splitSections.slice(0, childIndex + 1).join(VERSION_SEPARATOR),
        x.splitSections[childIndex],
      ]);
    });

    return Object.entries(groupByDict)
      .map(([k, v]) => {
        const [
          hasChildren, // 是否有子节点（子版本）
          currentNodeValue, // 当前节点的版本号（父版本的话带通配符）
          currentSegmentValue, // 当前节点的版本号对应当前小节的值，用来排序
        ] = JSON.parse(k);
        return {
          title: currentNodeValue,
          value: currentNodeValue,
          key: currentNodeValue,
          currentSegmentValue,
          ...(hasChildren && { children: recursiveRoutine(v, childIndex + 1) }),
        };
      })
      .sort((a, b) => VersionUtil.compareVersionSegment(b.currentSegmentValue, a.currentSegmentValue));
  }

  const optionList = versionList.map(x => ({
    ...x,
    splitSections: x.value.split(VERSION_SEPARATOR),
  }));

  const treeSelectVersionOptions = recursiveRoutine(optionList);

  /**
   * 精简树形结构，如果一个父节点只有1个子节点，就用这个子节点取代掉父节点
   */
  function shrinkTree(treeOptions) {
    return treeOptions.map((x) => {
      while (x.children && x.children.length === 1) {
        x = x.children[0];
      }
      if (x.children) {
        return {
          ...x,
          children: shrinkTree(x.children),
        };
      }
      return x;
    });
  }

  return shrinkTree(treeSelectVersionOptions);
}

/**
 *
 * @param treeSelectValue
 * @param {{ value: String }[]} versionList
 * @returns {string[]} 版本的value列表
 */
export function getVersionsFromTreeSelectValue(treeSelectValue, versionList) {
  return treeSelectValue.map((x) => {
    if (x.endsWith(WILDCARD_CHARACTER)) {
      const prefix = x.substring(0, x.length - WILDCARD_CHARACTER.length);
      return versionList.filter(y => y.value && y.value.startsWith(prefix)).map(z => z.value);
    }
    return [x];
  }).flat();
}
